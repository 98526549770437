import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';


function DynamicDirectorFields({ directors, onAddDirector, onRemoveDirector, onUpdateDirector }) {
  return (
    <div>
      {directors.map((director, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type='file'
            label={`Director ID ${index + 1}`}
            fullWidth
            variant="outlined"
            margin="normal"
            value={director}
            onChange={(e) => onUpdateDirector(index, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CloudUploadIcon />
                </InputAdornment>
              ),
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '8px' }}>
            {index === 0 ? null : ( // Prevent removal of the first field
              <button
                type='button'
                onClick={() => onRemoveDirector(index)}
                style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              >
                <DeleteIcon style={{ cursor: 'pointer' }} /> {/* "Delete" icon */}
              </button>
            )}
            <button
              type='button'
              onClick={() => onAddDirector(index)}
              style={{ border: 'none', background: 'transparent', cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
              <AddIcon style={{ cursor: 'pointer' }} /> {/* "Add" icon */}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function CorporateEntityForm() {
  const [cacFile, setCacFile] = useState(null);
  const [memorandumFile, setMemorandumFile] = useState(null);
  const [directorsFiles, setDirectorsFiles] = useState([""]); // Initialize with one empty director field
  const [bankAccountFile, setBankAccountFile] = useState(null);
  const [businessAddressFile, setBusinessAddressFile] = useState(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [bvn, setBVN] = useState(""); // BVN field

  const [isOpen, setIsOpen] = useState(false);

  const handleFileChange = (event, setter) => {
    const file = event.target.files[0];
    setter(file);
  };

  const handleDirectorChange = (index, value) => {
    const updatedDirectors = [...directorsFiles];
    updatedDirectors[index] = value;
    setDirectorsFiles(updatedDirectors);
  };

  const handleAddDirector = () => {
    const updatedDirectors = [...directorsFiles, ""];
    setDirectorsFiles(updatedDirectors);
  };

  const handleRemoveDirector = (index) => {
    if (index === 0) return; // Prevent removal of the first field
    const updatedDirectors = [...directorsFiles];
    updatedDirectors.splice(index, 1);
    setDirectorsFiles(updatedDirectors);
  };

  const handleSubmit = () => { 
    setIsOpen(true);
  }

  return (
    <>
      <Grid container spacing={2}>
        {/* CAC Certificate */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="cac-certificate">
            CAC Certificate & Application for Registration for Business Name form (CAC/BN/1 or Form A)
          </InputLabel>
          <Input
            id="cac-certificate"
            type="file"
            fullWidth
            variant="outlined"
            disableUnderline
            margin="normal"
            inputProps={{
              accept: "image/*",
            }}
            onChange={(event) => handleFileChange(event, setCacFile)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {/* Memorandum */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="memorandum">
            Memorandum and Articles of Association
          </InputLabel>
          <Input
            id="memorandum"
            type="file"
            fullWidth
            variant="outlined"
            disableUnderline
            margin="normal"
            inputProps={{
              accept: "image/*",
            }}
            onChange={(event) => handleFileChange(event, setMemorandumFile)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {/* Directors' IDs */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="directors">
            Directors’ IDs
          </InputLabel>
          <DynamicDirectorFields
            directors={directorsFiles}
            onAddDirector={handleAddDirector}
            onRemoveDirector={handleRemoveDirector}
            onUpdateDirector={handleDirectorChange}
          />
        </Grid>
        {/* BVN */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="bvn">
            BVN
          </InputLabel>
          <Input
            id="bvn"
            type="text"
            fullWidth
            placeholder='* BVN'
            disableUnderline
            variant="outlined"
            margin="normal"
            value={bvn}
            onChange={(event) => setBVN(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {/* Corporate Bank Account */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="bank-account">
            Corporate Bank Account details
          </InputLabel>
          <Input
            id="bank-account"
            type="file"
            fullWidth
            disableUnderline
            variant="outlined"
            margin="normal"
            inputProps={{
              accept: "image/*",
            }}
            onChange={(event) => handleFileChange(event, setBankAccountFile)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {/* Business Address */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="business-address">
            Operational Business Address
          </InputLabel>
          <Input
            id="business-address"
            type="file"
            fullWidth
            disableUnderline
            variant="outlined"
            margin="normal"
            inputProps={{
              accept: "image/*",
            }}
            onChange={(event) => handleFileChange(event, setBusinessAddressFile)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {/* License */}
        <Grid item xs={12}>
          <InputLabel style={{ fontSize: "14.5px" }} htmlFor="license">
            Operating License (where business is regulated)
          </InputLabel>
          <Input
            id="license"
            type="file"
            fullWidth
            disableUnderline
            variant="outlined"
            margin="normal"
            inputProps={{
              accept: "image/*",
            }}
            onChange={(event) => handleFileChange(event, setLicenseFile)}
            startAdornment={
              <InputAdornment position="start">
                <CloudUploadIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ color: "white", marginTop: "16px" }}
        >
          Submit
        </Button>
      </Grid>
    </>
  );
}

export default CorporateEntityForm;
