import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import CorporateEntityForm from './fields/coporate-entity';
import BusinessForm from './fields/business';
import NGOForm from './fields/ngos';
import InstitutionForm from './fields/public-institute';

function KycInfoCard() {
  const [businessType, setBusinessType] = useState('select');

  const businessTypes = [
    { label: 'Corporate Entity', value: 'corporate' },
    { label: 'Business (Sole Proprietorship/SME)', value: 'business' },
    { label: 'NGOs', value: 'ngos' },
    { label: 'Public Institution', value: 'institution' },
  ];

  const handleBusinessTypeChange = (event) => {
    const selectedType = event.target.value;
    setBusinessType(selectedType);
  };

  let selectedForm;

  switch (businessType) {
    case 'corporate':
      selectedForm = <CorporateEntityForm />;
      break;
    case 'business':
      selectedForm = <BusinessForm />;
      break;
    case 'ngos':
      selectedForm = <NGOForm />;
      break;
    case 'institution':
      selectedForm = <InstitutionForm />;
      break;
    default:
      selectedForm = null;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            KYC Information
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        <SoftBox>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel style={{ fontSize: "17.5px" }} htmlFor="business-type">
                  Select Business Type
                </InputLabel>
                <Select
                  id="business-type"
                  variant="outlined"
                  value={businessType}
                  onChange={handleBusinessTypeChange}
                  margin="normal"
                  fullWidth
                  style={{marginBottom:"2rem"}}
                  startAdornment={
                    <InputAdornment position="start">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="select">Select Business Type</MenuItem>
                  {businessTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
                {selectedForm} {/* Render the selected form */}
              </Grid>
            </Grid>
            
          </form>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default KycInfoCard;
