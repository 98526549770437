import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card"; // Import Card
import CardContent from "@mui/material/CardContent"; // Import CardContent
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Menu from "@mui/material/Menu"; // Import Menu
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem
import TextField from "@mui/material/TextField"; // Import TextField
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon
import Checkbox from "@mui/material/Checkbox"; // Import Checkbox
import Select from "@mui/material/Select"; // Import Select
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker
import logo from '../../assets/images/fairsure_logo.png'


import CsvDownloader from 'react-csv-downloader';









const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Create dummy data rows with headers
const rows = [
  {
    Name: "John Doe",
    Email: "johndoe@example.com",
    Amount: "$100",
    Reference: "REF-123",
    "Date/time": "2023-09-07 14:30:00",
    "Payment channel (card, transfer, QR)": "Card",
    Status: "Success",
  },
  {
    Name: "Jane Smith",
    Email: "janesmith@example.com",
    Amount: "$50",
    Reference: "REF-456",
    "Date/time": "2023-09-07 15:15:00",
    "Payment channel (card, transfer, QR)": "Transfer",
    Status: "Pending",
  },
  // Add more rows as needed
];

export default function TransactionScreenDialog({ isOpen, handleClose, rowData }) {
  const merchantName = rowData.Name;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false); // Define checked state
  const [selectedValue, setSelectedValue] = React.useState(0); // Define selectedValue state
  const [dateChecked, setDateChecked] = React.useState(false); // Define dateChecked state
  const [startDate, setStartDate] = React.useState(new Date()); // Define startDate state
  const [endDate, setEndDate] = React.useState(new Date()); // Define endDate state

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDateCheckboxChange = (event) => {
    setDateChecked(event.target.checked);
  };

  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", marginBottom: "1.5rem" }} elevation={0}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: "1rem" }}>
              <img src={logo} width={50} />
            </div>
            <div>
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Card sx={{ margin: "1rem" }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {merchantName} Transactions
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
              <div style={{display:'flex'}}>
                {/* Button that opens the menu */}
                <Button
                  aria-controls="filter-menu"
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  variant="outlined"
                  size="small"
                  sx={{
                    height: 30,
                    backgroundColor: "primary",
                    borderRadius: "8px",
                    color: "blue",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  Filter Options
                </Button>
                <CsvDownloader
                  datas={rowData}
                  filename={'fairsure_merchant_transactions'}
                  extension=".csv"
                >
                  <Button
                    variant="outlined"
                    style={{
                      flex: 1,
                      backgroundColor: 'white', // Use the lighter blue color
                      color: 'blue',
                      '&:hover': {
                        backgroundColor: 'darkblue',
                      },
                      fontSize: '12px', // Adjust the font size as needed
                    }}
                  >
                    Export to CSV
                  </Button>
                </CsvDownloader>
              </div>
              <div>
                <TextField
                  placeholder="Transaction ID"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 0 }}>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>

            {/* Menu */}
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: "400px",
                  width: "300px",
                },
              }}
            >
              <MenuItem>
                <div style={{ marginBottom: "4rem", width: "100%" }}>
                  <div style={{ marginBottom: "5px" }}>
                    <Checkbox checked={checked} onChange={handleCheckboxChange} />
                    Filter by Status
                  </div>
                  <Select value={selectedValue} style={{ marginTop: 10, width: "100%" }} onChange={handleValueChange}>
                    <MenuItem value={0}>Select Status</MenuItem>
                    <MenuItem value={1}>Successful</MenuItem>
                    <MenuItem value={2}>Pending</MenuItem>
                    <MenuItem value={3}>Authorized</MenuItem>
                    <MenuItem value={4}>Failed</MenuItem>
                    <MenuItem value={5}>Refund</MenuItem>
                  </Select>
                </div>
              </MenuItem>

              <MenuItem>
                <div style={{ marginBottom: "4rem", width: "100%" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Checkbox checked={dateChecked} onChange={handleDateCheckboxChange} />
                    Filter by Date
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ marginRight: "4px" }}>
                      <h5>Start</h5>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="custom-date-picker" />
                    </div>
                    <div style={{ marginLeft: "4px" }}>
                      <h5>End</h5>
                      <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="custom-date-picker" />
                    </div>
                  </div>
                </div>
              </MenuItem>
              {/* Add more filter options as needed */}
            </Menu>

            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead style={{ width: "100%" }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell>Date/time</TableCell>
                    <TableCell>Payment channel</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index} style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        {row.Name}
                      </TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>{row.Amount}</TableCell>
                      <TableCell>{row.Reference}</TableCell>
                      <TableCell>{row["Date/time"]}</TableCell>
                      <TableCell>{row["Payment channel (card, transfer, QR)"]}</TableCell>
                      <TableCell
                        style={{
                          color: "black",
                        }}
                      >
                        {row.Status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
}
