import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"; // Import TextField
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MerchantProfile from "./merchant-profile";
import logo from '../../assets/images/fairsure_logo.png'




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ isOpen, handleClose, rowData }) {
  const [comment, setComment] = React.useState(""); // State for the comment

  const merchantName = rowData.name;
  const merchantEmail = rowData.Email;
  const merchantPhone = rowData["Phone number"]

  const handleApprove = () => {
    // Implement your logic for approving the merchant here
    // You can show a success message or perform any necessary actions
  };

  const handleDelete = () => {
    // Implement your logic for deleting the merchant here
    // You can show a confirmation dialog and perform the deletion if confirmed
  };

  const handleAuthorize = () => {
    // Implement your logic for authorizing the merchant here
    // You can show a confirmation dialog and perform authorization if confirmed
  };

  const handleBlock = () => {
    // Implement your logic for blocking the merchant here
    // You can show a confirmation dialog and perform blocking if confirmed
  };

  const handleWhitelist = () => {
    // Implement your logic for whitelisting the merchant here
    // You can show a confirmation dialog and perform whitelisting if confirmed
  };

  const paragraphStyle = {
    fontSize: "20px",
    marginBottom: "8px", // Add margin at the bottom for spacing
  };

  const handleCommentChange = (event) => {
    // Update the comment state when the user types in the TextField
    setComment(event.target.value);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} elevation={0}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{marginTop:"1rem"}}> 
              <img src={logo} width={50}/>
            </div>
            <div>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <MerchantProfile 
          name={merchantName}
          email={merchantEmail}
          phone={merchantPhone}
        />
      </Dialog>
    </div>
  );
}
