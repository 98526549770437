import React, { useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "@mui/material/Button";

import TableContainer from "@material-ui/core/TableContainer";
import CreateTeamDialog from "./popup"; // Import your CreateTeamDialog component
import CreateBill from "./create-bill";

// Dummy billing data
const rows = [
  {
    billName: "Electricity Bill",
    amount: "₦100",
    amountType: "Fixed",
    dateCreated: "2023-09-15",
  },
  {
    billName: "Water Bill",
    amount: "₦50",
    amountType: "Fixed",
    dateCreated: "2023-09-14",
  },
  // Add more billing data as needed
];

export default function BillTable() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // State to manage the CreateTeamDialog
  const [isCreateBillOpen, setIsCreateBillOpen] = useState(false);

  const handleRowClick = (row) => {
    console.log("Row clicked:", row);
    setSelectedRow(row);
    setIsCreateDialogOpen(true); // Open the CreateTeamDialog when a row is clicked
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false); // Close the CreateTeamDialog
  };

  const handleCloseCreateBill = () => { 
    setIsCreateBillOpen(false);
  }

  return (
    <div style={{marginBottom:"5rem"}}>
      <div style={{display:"flex", justifyContent:"space-between", marginTop:"3rem", marginBottom:"5rem"}}>
        <h3>Billing</h3>
        <Button onClick={()=> setIsCreateBillOpen(true)}> 
          Create Bill
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead style={{ width: "100%" }}>
            <TableRow>
              <TableCell>Bill name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Amount type</TableCell>
              <TableCell>Date created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(row)}
              >
                <TableCell component="th" scope="row">
                  {row.billName}
                </TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.amountType}</TableCell>
                <TableCell>{row.dateCreated}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render CreateTeamDialog */}
      <CreateTeamDialog
        isOpen={isCreateDialogOpen}
        handleClose={handleCloseCreateDialog}
        rowData={selectedRow} // Pass the selected row's data to the dialog
      />

      <CreateBill 
        isOpen={isCreateBillOpen}
        handleClose={handleCloseCreateBill}
      />
    </div>
  );
}
