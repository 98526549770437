import React, { useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "@mui/material/Button";

import TableContainer from "@material-ui/core/TableContainer";
import CreateTeamDialog from "./popup"; // Import your CreateTeamDialog component
import CreateCharge from "./create-charge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";





// Dummy data for set charges
const setCharges = [
  {
    sn: 1,
    merchantName: "ABC Store",
    product: "Electronics",
    fee: "₦50",
    type: "Fixed",
    mode: "Online",
    minAmount: "₦0",
    maxAmount: "₦1000",
  },
  {
    sn: 2,
    merchantName: "XYZ Mart",
    product: "Clothing",
    fee: "₦30",
    type: "Percentage",
    mode: "In-store",
    minAmount: "₦100",
    maxAmount: "₦500",
  },
  {
    sn: 3,
    merchantName: "PQR Mall",
    product: "Furniture",
    fee: "₦25",
    type: "Fixed",
    mode: "Online",
    minAmount: "₦0",
    maxAmount: "₦800",
  },
  {
    sn: 4,
    merchantName: "LMN Supermarket",
    product: "Groceries",
    fee: "₦20",
    type: "Percentage",
    mode: "In-store",
    minAmount: "₦50",
    maxAmount: "₦700",
  },
  {
    sn: 5,
    merchantName: "EFG Electronics",
    product: "Appliances",
    fee: "₦40",
    type: "Fixed",
    mode: "Online",
    minAmount: "₦0",
    maxAmount: "₦1200",
  },
  {
    sn: 6,
    merchantName: "RST Fashion",
    product: "Apparel",
    fee: "₦35",
    type: "Percentage",
    mode: "In-store",
    minAmount: "₦80",
    maxAmount: "₦600",
  },
  {
    sn: 7,
    merchantName: "UVW Mart",
    product: "Electronics",
    fee: "₦45",
    type: "Fixed",
    mode: "Online",
    minAmount: "₦0",
    maxAmount: "₦900",
  },
  {
    sn: 8,
    merchantName: "GHI Furniture",
    product: "Home Decor",
    fee: "₦22",
    type: "Percentage",
    mode: "In-store",
    minAmount: "₦70",
    maxAmount: "₦550",
  },
  {
    sn: 9,
    merchantName: "JKL Supermarket",
    product: "Groceries",
    fee: "₦18",
    type: "Fixed",
    mode: "Online",
    minAmount: "₦0",
    maxAmount: "₦1000",
  },
  {
    sn: 10,
    merchantName: "MNO Electronics",
    product: "Gadgets",
    fee: "₦38",
    type: "Percentage",
    mode: "In-store",
    minAmount: "₦90",
    maxAmount: "₦750",
  },
  // Add more set charges data as needed
];

export default function SetChargesTable() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCreateChargeOpen, setIsCreateChargeOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRowClick = (row, event) => {
    event.stopPropagation(); 
    setSelectedRow(row);
    setIsCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
  };

  const handleCloseCreateCharge = () => {
    setIsCreateChargeOpen(false);
  };

  const handleActionsClick = (event) => {
    event.stopPropagation(); 
    setIsCreateDialogOpen(false);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = (event) => {
    event.stopPropagation();
    setIsCreateDialogOpen(false);
    setAnchorEl(null);
  };

  return (
    <div style={{ marginBottom: "5rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
          marginBottom: "5rem",
        }}
      >
        <h3>Set Charges</h3>
        <Button onClick={() => setIsCreateChargeOpen(true)}>
          Create Charge
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead style={{ width: "100%" }}>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Merchant Name</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Mode</TableCell>
              <TableCell>Min Amount</TableCell>
              <TableCell>Max Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setCharges.map((row, index) => (
              <TableRow
                key={index}
                style={{ cursor: "pointer" }}
                onClick={(e) => handleRowClick(row, e)}
              >
                <TableCell component="th" scope="row">
                  {row.sn}
                </TableCell>
                <TableCell>{row.merchantName}</TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>{row.fee}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.mode}</TableCell>
                <TableCell>{row.minAmount}</TableCell>
                <TableCell>{row.maxAmount}</TableCell>
                <TableCell>
                  <Button onClick={handleActionsClick}>Actions</Button>
                  <Menu
                    id={`menu-${index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseDropdown}
                  >
                    <MenuItem onClick={() => console.log("Edit clicked")}>
                      Suspend
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Delete clicked")}>
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Delete clicked")}>
                      Resume
                    </MenuItem>
                    <MenuItem onClick={(e) => handleCloseDropdown(e)}>
                      Close
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateTeamDialog
        isOpen={isCreateDialogOpen}
        handleClose={handleCloseCreateDialog}
        rowData={selectedRow}
      />

      <CreateCharge isOpen={isCreateChargeOpen} handleClose={handleCloseCreateCharge} />
    </div>
  );
}
