// EditMenuComponent.js
import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

function EditMenuComponent() {
  const [editMenuAnchor, setEditMenuAnchor] = useState(null);

  const handleOpenEditMenu = (event) => {
    setEditMenuAnchor(event.currentTarget);
  };

  const handleCloseEditMenu = () => {
    setEditMenuAnchor(null);
  };

  return (
    <>
      <Button onClick={handleOpenEditMenu}>Open Edit Menu</Button>
      <Menu
        id="edit-menu"
        anchorEl={editMenuAnchor}
        open={Boolean(editMenuAnchor)}
        onClose={handleCloseEditMenu}
      >
        <MenuItem onClick={handleCloseEditMenu}>Option 1</MenuItem>
        <MenuItem onClick={handleCloseEditMenu}>Option 2</MenuItem>
      </Menu>
    </>
  );
}

export default EditMenuComponent;
