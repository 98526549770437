import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TeamTable from './table';
import CreateTeamDialog from 'layouts/profile/create-team-popup';
// Define your table data
const rows = [
  { name: 'Name of team member', email: 'Email', userRole: 'User Role (admin and user)' },
  // Add more rows as needed
];

function TeamInfoCard({ title, description, action }) {

  const[isOpen, setIsOpen] = useState(false);

  const handleClose = () => { 
    setIsOpen(false);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
        <Button variant="contained" color="primary" style={{ color: "white", fontSize: "10px" }} onClick={()=> setIsOpen(true)}>
          + Add New User
        </Button>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
          
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
      </SoftBox>
      <TeamTable/>
      <CreateTeamDialog isOpen={isOpen} handleClose={handleClose}/>
    </Card>
    
  );
}

// Typechecking props for the TeamInfoCard
TeamInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default TeamInfoCard;
