import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import CoverLayout from "layouts/authentication/components/CoverLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SimpleBackdrop from "../backdrop"; // Import the SimpleBackdrop component

import man from "assets/images/curved-images/security.avif";
import logo from "assets/images/fairsure_logo.png";

import { MdCheckCircle } from 'react-icons/md'; // Import the check circle icon from react-icons library

function ForgotPassword() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState(""); // State to store the entered email
  const [status, setStatus] = useState(null); // State to store the API response status
  const [successMessage, setSuccessMessage] = useState(""); // State to store the success message
  const [loading, setLoading] = useState(false); // State to store loading state

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSendEmail = async () => {
    try {
      setLoading(true); // Set loading to true when making the API request

      // Create a request body with the "email" parameter
      const requestBody = {
        email: email,
      };

      // Make an API request to send a forgot password email
      const response = await axios.post("/api/forgot-password", requestBody);

      setStatus(response.status); // Set the status based on the API response

      if (response.status === 200) {
        // Request was successful, set the success message
        setSuccessMessage("Password reset email sent successfully.");

        // Clear the success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        // Request failed, handle the error message
        setStatus(400); // Set a custom status code to indicate error
      }
    } catch (error) {
      // An error occurred, handle the error message
      setStatus(500); // Set a custom status code to indicate error
    } finally {
      setLoading(false); // Set loading back to false when the API request is done
    }
  };

  useEffect(() => {
    // Clear the success message when the component unmounts
    return () => {
      setSuccessMessage("");
    };
  }, []);

  return (
    <CoverLayout
      title= {
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" width={40} height={40} style={{ marginRight: "8px" }} />
          <span>Account Recovery</span>
        </div>
      }
      description="Enter your email to get a recovery link"
      image={man}
    >
      <SimpleBackdrop open={loading} /> {/* Show the backdrop when loading is true */}
      <SoftBox>
        {successMessage ? ( // Render success message and icon if successMessage exists
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <MdCheckCircle size={80} color="green" style={{ marginBottom: "16px" }} /> {/* Green check icon */}
            <SoftTypography variant="body2">
              {successMessage}
            </SoftTypography>
          </div>
        ) : (
          <form>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Email
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state when input changes
              />
            </SoftBox>
            
            {status === 400 && ( // Render error message if status is 400
              <SoftTypography variant="body2" color="error" mb={2}>
                Failed to send password reset email. Please try again.
              </SoftTypography>
            )}
            
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSendEmail} // Call the handleSendEmail function when the button is clicked
              >
                Send
              </SoftButton>
            </SoftBox>
          </form>
        )}
        
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ForgotPassword;
