import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  width: '100%', // Make the dialog take the full width
};

const labelStyle = {
  fontWeight: 'bold',
  marginRight: '1rem',
};

const customArrowStyle = {
  color: 'black', // Change the color of the dropdown icon
  fontSize: '2rem', // Adjust the size of the dropdown icon
};


export default function CreateCharge({ isOpen, handleClose, rowData }) {
  const [merchant, setMerchant] = useState('0');
  const [product, setProduct] = useState('0');
  const [fee, setFee] = useState('');
  const [type, setType] = useState('0');
  const [mode, setMode] = useState('0');
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'merchant') {
      setMerchant(value);
    } else if (name === 'product') {
      setProduct(value);
    } else if (name === 'fee') {
      setFee(value);
    } else if (name === 'type') {
      setType(value);
    } else if (name === 'mode') {
      setMode(value);
    } else if (name === 'minAmount') {
      setMinAmount(value);
    } else if (name === 'maxAmount') {
      setMaxAmount(value);
    }
  };

  const handleSave = async () => {
    // Implement logic to save the charge data here
    // You can access the individual state variables (merchant, product, fee, etc.)

    // Simulate a delay to show the success message
    await new Promise(resolve => setTimeout(resolve, 1000));

    setShowSuccess(true);

    // Close the dialog after a short delay
    setTimeout(() => {
      setShowSuccess(false);
      handleClose();
    }, 3000);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Create Charge</DialogTitle>
      <DialogContent>
      {showSuccess ? (
          <div style={{ textAlign: 'center' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '4rem', marginBottom: '1rem' }} />
            <DialogContentText>
              Charge created successfully!
            </DialogContentText>
          </div>
        ) :(
        <DialogContentText>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Select Merchant:</span>
            <FormControl fullWidth>
              <Select
                name="merchant"
                value={merchant}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon style={customArrowStyle}/>
                  </InputAdornment>
                }
              >
                {/* Add your merchant options here */}
                <MenuItem value="0" style={{display:"none"}}>Select Merchant</MenuItem>
                <MenuItem value="Merchant1">Merchant 1</MenuItem>
                <MenuItem value="Merchant2">Merchant 2</MenuItem>
                {/* Add more merchants as needed */}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Select Product:</span>
            <FormControl fullWidth>
              <Select
                name="product"
                value={product}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon style={customArrowStyle}/>
                  </InputAdornment>
                }
              >
                {/* Add your product options here */}
                <MenuItem value="0" style={{display:"none"}}>Select Product</MenuItem>
                <MenuItem value="Airtime">Airtime</MenuItem>
                <MenuItem value="Electricity">Electricity</MenuItem>
                {/* Add more products as needed */}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Set Fee:</span>
            <TextField
              name="fee"
              placeholder='Set Fee'
              value={fee}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Select Type:</span>
            <FormControl fullWidth>
              <Select
                name="type"
                value={type}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon style={customArrowStyle}/>
                  </InputAdornment>
                }
              >
                {/* Add your type options here */}
                <MenuItem value="0" style={{display:"none"}}>Select Type</MenuItem>
                <MenuItem value="Charge">Charge</MenuItem>
                <MenuItem value="Commission">Commission</MenuItem>
                {/* Add more types as needed */}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Select Mode:</span>
            <FormControl fullWidth>
              <Select
                name="mode"
                value={mode}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon style={customArrowStyle}/>
                  </InputAdornment>
                }
              >
                {/* Add your mode options here */}
                <MenuItem value="0" style={{display:"none"}}>Select Mode</MenuItem>
                <MenuItem value="Percentage">Percentage</MenuItem>
                <MenuItem value="Fixed">Fixed</MenuItem>
                {/* Add more modes as needed */}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Set Min Amount:</span>
            <TextField
              name="minAmount"
              placeholder='Min Amount'
              value={minAmount}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '13px' }}>Set Max Amount:</span>
            <TextField
              name="maxAmount"
              placeholder='Max Amount'
              value={maxAmount}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
        </DialogContentText>
        )}
      </DialogContent>
      {!showSuccess && (
        <DialogActions>
          <Button onClick={handleClose} className="small-button">
            Close
          </Button>
          <Button onClick={handleSave} className="small-button">
            Create
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
