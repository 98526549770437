import React, { useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import logo from "assets/images/fairsure_logo.png";
import man from "assets/images/curved-images/confirm.avif";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import SimpleBackdrop from "../backdrop";
import SimpleSnackbar from "../snackbar";

function ConfirmCode() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Create refs for the input fields
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const inputStyle = {
    flex: 1,
    width: '50px',
    height: '50px',
    fontSize: '20px',
    textAlign: 'center',
    marginRight: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "5px",
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (index === 0) setInput1(value);
    else if (index === 1) setInput2(value);
    else if (index === 2) setInput3(value);
    else if (index === 3) setInput4(value);
    else if (index === 4) setInput5(value);
    else setInput6(value);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
    
    // Check if the user pressed the "Backspace" key and the input is empty
    if ((e.key === 'Backspace' || e.key === 'Delete') && value.length === 0 && index > 0) {
      // Move focus to the previous input field
      inputRefs[index - 1].current.focus();
      return; // Don't update the input value
    }
  };

  const handleSendOtp = async () => {
    // Prepare the OTP value by concatenating all input fields
    const otpValue = `${input1}${input2}${input3}${input4}${input5}${input6}`;

    // Create a data object to send in the request
    const data = {
      otp: otpValue,
    };

    // Define the URL for your OTP submission API
    const apiUrl = "/api/two-fa/authenticate";

    setLoading(true); // Set loading to true when the request is happening

    try {

      // Retrieve the token from localStorage
      const token = localStorage.getItem('token');

      // Set the Authorization header in the request
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      // Send a POST request to the server with the OTP data
      const response = await axios.post(apiUrl, data, config);

      // Handle the response from the server
      if (response.status === 200) {
        const responseData = response.data.data;

        localStorage.clear()
        console.log('cleared storage :', localStorage.getItem('token'));
  
        // Store individual data fields in local storage
        localStorage.setItem('userId', responseData.id.toString());
        localStorage.setItem('firstName', responseData.firstName);
        localStorage.setItem('lastName', responseData.lastName);
        localStorage.setItem('email', responseData.email);
        localStorage.setItem('phone', responseData.phone);
        localStorage.setItem('balance', responseData.balance.toString());
        localStorage.setItem('role', responseData.role);
        localStorage.setItem('lastLoginDate', responseData.lastLoginDate);
        localStorage.setItem('isActive', responseData.isActive.toString());
        localStorage.setItem('token', responseData.token);
        localStorage.setItem('isTwoFaSetup', responseData.isTwoFaSetup);
        localStorage.setItem('isTwoFaEnabled', responseData.isTwoFaEnabled);
  
        console.log("saved data successfully");
        console.log(responseData.token);
        navigate("/dashboard"); // Navigate to a success page

        setTimeout(() => {
          setLoading(false); // Set loading to false after 5 seconds
          setSuccess(true); // Set success to true
          setError(null); // Clear any previous error
  
          
        }, 5000);
        return true; // Indicate a successful login

      } else {
        // Handle errors or display an error message
        console.log("OTP submission failed.");
      }
    } catch (error) {
      // Handle any network or request errors
      setLoading(false);
      setSuccess(false);
      setError("Login Failed");
      console.error('An error occurred during login:', error);
      return false; // Indicate login failure due to an error
    }
  };

  return (
    <CoverLayout
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" width={40} height={40} style={{ marginRight: "8px" }} />
          <span>Confirmation Code</span>
        </div>
      }
      description="Please insert your OTP code from your authenticator app"
      image={man}
    >
      {/* Display backdrop when loading */}
      {loading && <SimpleBackdrop open={loading} handleClose={() => setLoading(false)} />}

      {/* Show error Snackbar */}
      {error && <SimpleSnackbar variant="error" open={true} messages={error} handleClose={()=> setError(null)}/>}

      {/* Show success Snackbar */}
      {success && <SimpleSnackbar variant="success" open={success} handleClose={()=> setSuccess(false)} message={"Login Successful"}/>}

      {/* ... (rest of your code) */}
      <SoftBox component="form" role="form">
        <SoftBox mb={1} style={{ display: "flex", justifyContent: "space-between" }}>
          {Array.from({ length: 6 }, (_, index) => (
            <input
              key={index}
              type="text"
              placeholder='*'
              value={
                index === 0 ? input1 :
                index === 1 ? input2 :
                index === 2 ? input3 :
                index === 3 ? input4 :
                index === 4 ? input5 :
                input6
              }
              onChange={(e) => handleInputChange(e, index)}
              onKeyUp={(e) => handleInputChange(e, index)}
              style={inputStyle}
              ref={inputRefs[index]} // Attach the ref to the input field
            />
          ))}
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSendOtp}
          >
            Confirm
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ConfirmCode;
