import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftBox from "components/SoftBox";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import success icon
import CancelIcon from '@mui/icons-material/Cancel'; // Import error icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


export default function MessageDialog({ success, error, isOpen, handleClose, isLoading, type, message }) {
  // ... (other code remains unchanged)

  return (
    <div>
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: '10px',
            padding: '1rem',
            width: '100%',
          },
        }}
      >
        <DialogTitle>{type}</DialogTitle>
        <DialogContent>
          {/* ... (your existing content) */}
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop='3rem'>
            {isLoading && <CircularProgress color="primary" />}

            {success ? (
              <>
                <FontAwesomeIcon icon={faCheckCircle} color='green' fontSize={100}/>
                <p style={{ color: 'green' }}>{message}</p>
              </>
            ) : null}

            {error ? (
              <>
                <FontAwesomeIcon icon={faTimes} color='red' fontSize={100}/>
                <p style={{ color: 'red' }}>{message}</p>
              </>
            ) : null}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              flex: 1,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'grey',
              },
              fontSize: '12px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
