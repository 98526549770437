import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  width: '100%', // Make the dialog take the full width
};

const labelStyle = {
  fontWeight: 'bold',
  marginRight: '1rem',
};

export default function CreateBill({ isOpen, handleClose, rowData }) {
  const { billName, amount, amountType, dateCreated } = rowData || {};
  const [billNameValue, setBillNameValue] = useState(billName || '');
  const [amountValue, setAmountValue] = useState(amount || '');
  const [isFixed, setIsFixed] = useState(amountType === 'Fixed');
  const [isPublicValue, setIsPublicValue] = useState('Private'); // Assume the bill starts as private

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === 'isFixed') {
      setIsFixed(checked);
      if (!checked) {
        setAmountValue(''); // Clear the amount if not fixed
      }
    } else if (name === 'billName') {
      setBillNameValue(value);
    } else if (name === 'amount') {
      setAmountValue(value);
    } else if (name === 'isPublic') {
      setIsPublicValue(value);
    }
  };

  const handleSave = () => {
    // Implement logic to save the edited data here
    // You can access the individual state variables
    handleClose(); // Close the dialog after saving
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Bill Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Name of Bill:</span>
            <TextField
              name="billName"
              value={billNameValue}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Set Amount:</span>
            {isFixed && (
              <TextField
                name="amount"
                value={amountValue}
                onChange={handleInputChange}
                fullWidth
              />
            )}
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Select if amount is fixed or not:</span>
            <FormControlLabel
              control={
                <Checkbox
                  name="isFixed"
                  checked={isFixed}
                  onChange={handleInputChange}
                  color="primary"
                />
              }
              label="Fixed"
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Set Bill to Public or Private:</span>
            <FormControl fullWidth>
              <Select
                name="isPublic"
                value={isPublicValue}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                } // Use the dropdown icon
              >
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'grey',
            },
            fontSize: '9px', // Apply the custom style
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSave}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: 'green', // Change the color for save button
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkgreen',
            },
            fontSize: '9px', // Apply the custom style
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
