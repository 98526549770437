/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import { Select, MenuItem } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles'; // Import useTheme




import { useNavigate } from "react-router-dom"; // Import useHistory from react-router-dom



// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";



// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import man from "assets/images/curved-images/man5.jpg";
import logo from "assets/images/fairsure_logo.png"
import DropDown from "components/SoftDropDown";
import { RegisterService } from "services/AuthService";

import axios from 'axios'; // Import Axios
import SimpleBackdrop from "../backdrop";
import SimpleSnackbar from "../snackbar";






function SignUp() {

  // eslint-disable-next-line no-restricted-globals
  const navigate = useNavigate();

  const [agreement, setAgremment] = useState(true);

  const handleSetAgremment = () => setAgremment(!agreement);

  const [selectedName, setSelectedName] = useState(""); // State to track selected name

  const handleNameChange = (event) => {
    setSelectedName(event.target.value); // Update selected name when dropdown value changes
  };
  

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const [corporateAddress, setCorporateAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bussinessRegNumber, setBussinessRegNumber] = useState('');
  
  const [selectedValue, setSelectedValue] = useState(0); // Initial selected value
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const theme = useTheme(); // Get the current theme





  const marginStyle = {
    marginBottom: '1.45rem', // Default margin for all screen sizes
    // Define responsive margins based on screen size
    [theme.breakpoints.only('xs')]: {
      marginLeft: '15rem', // Extra small screens (phones)
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '16rem', // Small screens (tablets)
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '16rem', // Medium screens (laptops)
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: '17rem', // Large screens (desktops)
    },
    [theme.breakpoints.only('xl')]: {
      marginLeft: '25rem', // Extra-large screens (large desktops)
    },
  };





  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  }


  const isMinLengthValid = (password) => {
    return password.length >= 8;
  };

  const isLowerCaseValid = (password) => {
    return /[a-z]/.test(password);
  };

  const isUpperCaseValid = (password) => {
    return /[A-Z]/.test(password);
  };

  const isDigitValid = (password) => {
    return /\d/.test(password);
  };

  const isSpecialCharValid = (password) => {
    return /[!@#$%^&*]/.test(password);
  };

  const getPasswordIconColor = (isValid) => {
    return isValid ? 'green' : 'red';
  };







  

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  
    // Validate the email and set the error state
    setIsEmailValid(isValidEmail(newEmail));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordBlur = () => {
    if (password !== confirmPassword) {
      setPasswordMatch(false); // Set passwordMatch to false if passwords don't match
    } else {
      setPasswordMatch(true); // Set passwordMatch to true if passwords match
    }
  };






  const handleRegistration = async () => {
    setLoading(true); // Set loading to true when the request is happening
    try {
      const registrationData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        merchantName: organizationName,
        merchantTypeId: 1,
        address: corporateAddress,
        password: password,
        passwordConfirmation: confirmPassword,
        regBusinessNo: bussinessRegNumber
      };
  
      // Use Axios to make the POST request
      console.log("sending request");
      const response = await axios.post('/api/register', registrationData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log(response);
  
      // Check the response and handle success or error
      if (response.status === 200) {
        console.log('User registered successfully!');
        setTimeout(() => {
          setLoading(false); // Set loading to false after 5 seconds
          setSuccess(true); // Set success to true
          setError(null); // Clear any previous error
  
          // Navigate to the login route after a successful registration
          navigate("/authenticate/confirm-email");
        }, 5000);
        // Perform any necessary actions after successful registration
      } else {
        setLoading(false)
        setSuccess(false)
        setError("Registration Failed")
        console.error('Registration failed:', response.data.message);
        // Handle the registration failure, show error messages, etc.
      }
    } catch (error) {
      console.error('An error occurred during registration:', error);
      setLoading(false); // Set loading to false on error
      setSuccess(false); // Set success to false
      setError(error.response?.data?.message || "An error occurred"); // Set error message

      // Handle unexpected errors during registration
    }
  };
  // Call the handleRegistration function when you want to register a user
  
  
  // Call the handleRegistration function when you want to register a user
  //handleRegistration()
  
  


  return (
    
    <BasicLayout
      title=<img src={logo} height={90} width={90}/>
      description="Become a member of the fairsure family today, sign up below."
      image={man}
    >
      {/* Display backdrop when loading */}
      {loading && <SimpleBackdrop open={loading} handleClose={() => setLoading(false)} />}

      {/* Show error Snackbar */}
      {error && <SimpleSnackbar variant="error" open={true} messages={error} handleClose={()=> setError(null)}/>}

      {/* Show success Snackbar */}
      {success && <SimpleSnackbar variant="success" open={success} handleClose={()=> setSuccess(false)} message={"Registration Successful"}/>}

      {/* ... (rest of your code) */}

      <Card >
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register with fairsure
          </SoftTypography>
        </SoftBox>
        
        {/*<SoftBox mb={2}>
          <Socials />
        </SoftBox>

  <Separator />*/}
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                placeholder="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                sx={{ borderColor: isEmailValid ? '' : 'red' }}
              />
              {!isEmailValid && (
                <Typography variant="caption" color="red" sx={{ marginTop: 1 }}>
                  *Please enter a valid email address.
                </Typography>
              )}
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                placeholder="Name of Organization"
                value={organizationName}
                onChange={e => setOrganizationName(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <Select placeholder="Type of Organization" value={selectedValue} style={{ marginTop: 10, width:"100%"}} IconComponent={() => <ArrowDropDownIcon />} onChange={handleValueChange}>
                <MenuItem value={0}>Select Organization</MenuItem>
                <MenuItem value={1}>SME/Sole proprietorship</MenuItem>
                <MenuItem value={2}>Corporate Entity</MenuItem>
                <MenuItem value={3}>NGOs</MenuItem>
                <MenuItem value={4}>Public Institution</MenuItem>
              </Select>
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                type="number"
                placeholder="Bussiness Registration No"
                value={bussinessRegNumber}
                onChange={e => setBussinessRegNumber(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                placeholder="Corporate Address (optional)"
                value={corporateAddress}
                onChange={e => setCorporateAddress(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton sx={marginStyle} onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              
            </SoftBox>
        {/* Rest of your form */}
      
    
            <p style={{color:'black', fontSize:12}}>Password must:</p>
            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isMinLengthValid(password) ? (
                  <Icon
                    component={CheckCircleOutlineIcon}
                    sx={{
                      color: 'green',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                ) : (
                  <Icon
                    component={CancelIcon}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                )}
              </span>
              {/* Password requirements */}
              <Typography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Be a minimum of 8 Characters with
              </Typography>
            </SoftBox>

            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
              {isLowerCaseValid(password) ? (
                  <Icon
                    component={CheckCircleOutlineIcon}
                    sx={{
                      color: 'green',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                ) : (
                  <Icon
                    component={CancelIcon}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                )}
              </span>
              {/* Password requirements */}
              <Typography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Lowercase letter (a-z)
              </Typography>
            </SoftBox>

            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
              {isUpperCaseValid(password) ? (
                  <Icon
                    component={CheckCircleOutlineIcon}
                    sx={{
                      color: 'green',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                ) : (
                  <Icon
                    component={CancelIcon}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                )}
              </span>
              {/* Password requirements */}
              <Typography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Uppercase letter (A-Z)
              </Typography>
            </SoftBox>

            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
              {isDigitValid(password) ? (
                  <Icon
                    component={CheckCircleOutlineIcon}
                    sx={{
                      color: 'green',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                ) : (
                  <Icon
                    component={CancelIcon}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                )}
              </span>
              {/* Password requirements */}
              <Typography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one number (0-9)
              </Typography>
            </SoftBox>

            <SoftBox mb={2} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
              {isSpecialCharValid(password) ? (
                  <Icon
                    component={CheckCircleOutlineIcon}
                    sx={{
                      color: 'green',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                ) : (
                  <Icon
                    component={CancelIcon}
                    sx={{
                      color: 'red',
                      cursor: 'pointer',
                      marginLeft: '12px',
                      marginRight: '3px',
                    }}
                  />
                )}
              </span>
              {/* Password requirements */}
              <Typography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Spaecial Character
              </Typography>
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput  
               type={showPassword ? 'text' : 'password'}
                placeholder="Confirm Password"  
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                onBlur={handleConfirmPasswordBlur}
                sx={{ borderColor: passwordMatch ? '' : 'red',}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton sx={marginStyle} onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!passwordMatch && ( // Display error message when passwords don't match
                <Typography variant="caption" color="red" sx={{ marginTop: 1 }}>
                  *Password does not match
                </Typography>
              )}
            </SoftBox>


            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                Terms and Conditions
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={handleRegistration}
                disabled={
                  !isEmailValid ||
                  !passwordMatch ||
                  !isMinLengthValid(password) ||
                  !isLowerCaseValid(password) ||
                  !isUpperCaseValid(password) ||
                  !isDigitValid(password) ||
                  !isSpecialCharValid(password)
                }
              >
                Sign Up
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
