import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField"; // Import Material-UI TextField
import Button from "@mui/material/Button"; // Import Material-UI Button
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid"; // Import Material-UI Grid
import { styled } from '@mui/material/styles'; // Import styled from Material-UI
import InputLabel from "@mui/material/InputLabel"; // Import Material-UI InputLabel

// Define custom styles using the `styled` function
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%', // Default width for xs screen size
  [theme.breakpoints.up('sm')]: {
    width: '100%', // 60% width for sm screen size and larger
  },
  [theme.breakpoints.up('md')]: {
    width: '65%', // 40% width for md screen size and larger
  },
  [theme.breakpoints.up('lg')]: {
    width: '45%', // 30% width for lg screen size and larger
  },
}));

function PersonalInfoCard({ title, description, action }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');
    const storedPhoneNumber = localStorage.getItem('phone');

    if (storedFirstName) {
      setFirstName(storedFirstName);
    }

    if (storedLastName) {
      setLastName(storedLastName);
    }

    if (storedEmail) {
      setEmail(storedEmail);
    }

    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []); // The empty dependency array ensures this effect runs once when the component mounts

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // You can access individual form field values using the state variables
    console.log('First Name:', firstName);
    console.log('Last Name:', lastName);
    console.log('Email:', email);
    console.log('Phone Number:', phoneNumber);
    // Add logic for submitting the data to your server or performing other actions
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        <SoftBox>
          {/* Form fields */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="first-name">First Name</InputLabel>
                <CustomTextField
                  id="first-name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="last-name">Last Name</InputLabel>
                <CustomTextField
                  id="last-name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="email">Email</InputLabel>
                <CustomTextField
                  id="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="phone-number">Phone Number</InputLabel>
                <CustomTextField
                  id="phone-number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Button
              type="save"
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Save Changes
            </Button>
          </form>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
PersonalInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default PersonalInfoCard;
