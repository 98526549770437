import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import ProfileSettings from './components/ProfileSettings';
import MerchantSettings from './components/MerchantSettings';
import PersonalInfoCard from './InfoCards/PersonalInfoCard';
import BusinessInfoCard from './InfoCards/BusinessInfoCard';
import TeamInfoCard from './InfoCards/TeamInfoCard';
import KycInfoCard from './InfoCards/KycInfoCard';
import SecurityInfoCard from './InfoCards/SecurityInfoCard';




function LabTabs({name, merchantemail, phone}) {
  const [value, setValue] = useState('1');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Personal Information"
              value="1"
              sx={{
                fontSize: { xs: 9, sm: 10, md: 10, lg: 12, xl: 14 }, // Adjust the font size for different screen sizes
              }}
            />
            <Tab
              label="Business Information"
              value="2"
              sx={{
                fontSize: { xs: 9, sm: 10, md: 10, lg: 12, xl: 14 }, // Adjust the font size for different screen sizes
              }}
            />
            
            <Tab
              label="Merchant Documents"
              value="3"
              sx={{
                fontSize: { xs: 9, sm: 10, md: 10, lg: 12, xl: 14 }, // Adjust the font size for different screen sizes
              }}
            />
            {/*
            <Tab
              label="KYC"
              value="4"
              sx={{
                fontSize: { xs: 9, sm: 10, md: 10, lg: 12, xl: 14 }, // Adjust the font size for different screen sizes
              }}
            />
            <Tab
              label="Security"
              value="5"
              sx={{
                fontSize: { xs: 9, sm: 10, md: 10, lg: 12, xl: 14 }, // Adjust the font size for different screen sizes
              }}
            />*/}
            
          </TabList>
        </Box>
        <TabPanel value="1"> 
          {value === '1' && (
            <SoftBox mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={12}>
                  <PersonalInfoCard title={'Personal Information'} description={"Merchant personal details "} name={name} merchantemail={merchantemail} phone={phone}/>
                </Grid>
                
              </Grid>
            </SoftBox>
          )}
        </TabPanel>
        <TabPanel value="2">
            <SoftBox mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} xl={12}>
                  <BusinessInfoCard title={"Business Information"} description={"Merchant business details"}/>
                </Grid>
                
              </Grid>
            </SoftBox> 
        </TabPanel>
        <TabPanel value="3">
            <SoftBox mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} xl={12}>
                  <TeamInfoCard title={"Merchant Document"} description={"List of Merchant Compliance Document"}/>
                </Grid>
                
              </Grid>
            </SoftBox> 
        </TabPanel>
        {/*
        <TabPanel value="4">
            <SoftBox mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} xl={12}>
                  <KycInfoCard/>
                </Grid>
                
              </Grid>
            </SoftBox> 
        </TabPanel>
        <TabPanel value="5">
            <SoftBox mt={5} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} xl={12}>
                  <SecurityInfoCard/>
                </Grid>
                
              </Grid>
            </SoftBox> 
        </TabPanel>
          */}
      </TabContext>
    </Box>
  );
}

export default LabTabs;
