import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftBox from "components/SoftBox";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';



export default function AlertDialog2({ link, isOpen, enable2Fa, handleClose, isLoading }) {
  const [otp, setOtp] = useState(''); // State variable to store OTP

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Create refs for the input fields
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const inputStyle = {
    flex: 1,
    width: '50px',
    height: '50px',
    fontSize: '20px',
    textAlign: 'center',
    marginRight: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "5px",
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (index === 0) setInput1(value);
    else if (index === 1) setInput2(value);
    else if (index === 2) setInput3(value);
    else if (index === 3) setInput4(value);
    else if (index === 4) setInput5(value);
    else setInput6(value);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
    
    // Check if the user pressed the "Backspace" key and the input is empty
    if ((e.key === 'Backspace' || e.key === 'Delete') && value.length === 0 && index > 0) {
      // Move focus to the previous input field
      inputRefs[index - 1].current.focus();
      return; // Don't update the input value
    }
  };

  const handleConfirm = () => {
    const otpValue = `${input1}${input2}${input3}${input4}${input5}${input6}`;
    // You can use the 'otp' state variable here for further processing
    console.log('Entered OTP:', otpValue);

    // Close the dialog
    enable2Fa(otpValue);
    enable2Fa(otpValue);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: '10px',
            padding: '1rem', // Adjust the padding as needed
            width: '100%', // Make the dialog take the full width
          },
        }}
      >
        <DialogTitle>{"Follow steps below to setup 2FA"}</DialogTitle>
        <DialogContent>
          <ul style={{color:'grey', fontSize:'13px', marginBottom:'2rem'}}> 
            <li>Download an authenticator app (Google auth, Microsoft auth....)</li>
            <li>Scan the QRcode below to setup 2FA authentication</li>
            <li>Please insert otp to enable 2FA authentication</li>
          </ul>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <img src={link} alt="QR Code" style={{ maxWidth: '100%', maxHeight: '200px' }} />
          </Box>
          <ul style={{color:'grey', fontSize:'13px', marginBottom:'2rem'}}> 
            <li>Please insert otp to enable 2FA authentication</li>
          </ul>
          <DialogContentText>
            <SoftBox mb={1} style={{ display: "flex", justifyContent: "space-between" }}>
              {Array.from({ length: 6 }, (_, index) => (
                <input
                  key={index}
                  type="number"
                  length='1'
                  placeholder='*'
                  value={
                    index === 0 ? input1 :
                    index === 1 ? input2 :
                    index === 2 ? input3 :
                    index === 3 ? input4 :
                    index === 4 ? input5 :
                    input6
                  }
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyUp={(e) => handleInputChange(e, index)}
                  style={inputStyle}
                  ref={inputRefs[index]} // Attach the ref to the input field
                />
              ))}
            </SoftBox>
          </DialogContentText>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop='3rem'>
            {isLoading && <CircularProgress color="primary" />}
          </Box>
        </DialogContent>
        <DialogActions>
          
          <Button
            onClick={handleConfirm}
            style={{
              flex: 1,
              backgroundColor: 'white',
              color: 'primary',
              '&:hover': {
                backgroundColor: 'grey',
              },
              fontSize: '12px', // Adjust the font size as needed
            }}
          >
            Confirm
          </Button>

          <Button
            onClick={handleClose}
            style={{
              flex: 1,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'grey',
              },
              fontSize: '12px', // Adjust the font size as needed
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
