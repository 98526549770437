/**
 * =========================================================
 * Soft UI Dashboard React - v4.0.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card"; // Import the Card component
import CardContent from "@mui/material/CardContent"; // Import the CardContent component

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import PublicIcon from "@mui/icons-material/Public"; // Import the PublicIcon component
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the CheckCircleIcon component
import CancelIcon from "@mui/icons-material/Cancel"; // Import the CancelIcon component
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder"; // Import the QueryBuilderIcon component
import BillTable from "./bill-table";

function Billing() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <CardContent>
          <BillTable />
        </CardContent>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
