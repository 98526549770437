/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import man from "assets/images/curved-images/man3.jpeg";
import logo from "assets/images/fairsure_logo.png"
import { LoginService } from "services/AuthService";
import SimpleBackdrop from "../backdrop";
import SimpleSnackbar from "../snackbar";
import { useNavigate } from "react-router-dom"; // Import useHistory from react-router-dom
import { useTheme } from '@mui/material/styles'; // Import useTheme

import axios from 'axios'; // Import Axios


function SignIn() {

  const theme = useTheme(); // Get the current theme

  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);



  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");



  const marginStyle = {
    marginBottom: '1.45rem', // Default margin for all screen sizes
    // Define responsive margins based on screen size
    [theme.breakpoints.only('xs')]: {
      marginLeft: '16rem', // Extra small screens (phones)
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '16rem', // Small screens (tablets)
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '13rem', // Medium screens (laptops)
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: '20rem', // Large screens (desktops)
    },
    [theme.breakpoints.only('xl')]: {
      marginLeft: '13rem', // Extra-large screens (large desktops)
    },
  };


  

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };





  const handleLogin = async () => {
    setLoading(true); // Set loading to true when the request is happening
  
    try {
      const loginData = {
        email: email,
        password: password,
      };
  
      console.log("sending request");
      const response = await axios.post('/api/authenticate', loginData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        const responseData = response.data.data;
  
        // Store individual data fields in local storage
        localStorage.setItem('userId', responseData.id.toString());
        localStorage.setItem('firstName', responseData.firstName);
        localStorage.setItem('lastName', responseData.lastName);
        localStorage.setItem('email', responseData.email);
        localStorage.setItem('phone', responseData.phone);
        localStorage.setItem('balance', responseData.balance.toString());
        localStorage.setItem('role', responseData.role);
        localStorage.setItem('lastLoginDate', responseData.lastLoginDate);
        localStorage.setItem('isActive', responseData.isActive.toString());
        localStorage.setItem('token', responseData.token);
        localStorage.setItem('isTwoFaSetup', responseData.isTwoFaSetup);
        localStorage.setItem('isTwoFaEnabled', responseData.isTwoFaEnabled);
  
        console.log("saved data successfully");
        console.log(responseData.token);
        const isTwoFaEnabled = localStorage.getItem('isTwoFaEnabled');
          if (isTwoFaEnabled === 'true' || isTwoFaEnabled === true) {
            // Two-factor authentication is enabled, navigate to the confirm-otp route
            navigate('/authentication/confirm-otp');
          } else {
            // Two-factor authentication is not enabled, navigate to the dashboard
            navigate('/dashboard');
          }
  
        setTimeout(() => {
          setLoading(false); // Set loading to false after 5 seconds
          setSuccess(true); // Set success to true
          setError(null); // Clear any previous error
  
          
        }, 5000);
        return true; // Indicate a successful login
      } else {
        return false; // Indicate a login failure
      }
    } catch (error) {
      setLoading(false);
      setSuccess(false);
      setError("Login Failed");
      console.error('An error occurred during login:', error);
      return false; // Indicate login failure due to an error
    }
  };


  return (
    <CoverLayout
      title= {
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" width={40} height={40} style={{ marginRight: "8px" }} />
          <span>Welcome back</span>
        </div>
      }
      description="Enter your email and password to sign in"
      image={man}
    >
      {/* Display backdrop when loading */}
      {loading && <SimpleBackdrop open={loading} handleClose={() => setLoading(false)} />}

      {/* Show error Snackbar */}
      {error && <SimpleSnackbar variant="error" open={true} messages={error} handleClose={()=> setError(null)}/>}

      {/* Show success Snackbar */}
      {success && <SimpleSnackbar variant="success" open={success} handleClose={()=> setSuccess(false)} message={"Login Successful"}/>}

      {/* ... (rest of your code) */}
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput value={email} onChange = {e => setEmail(e.target.value)} type="email" placeholder="Email" />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton sx={marginStyle} onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
            <SoftButton onClick={handleLogin} variant="gradient" color="info" fullWidth>
              Sign in
            </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
          <h4 style={{fontSize:"12px", color:"lightblue"}}><Link style={{color:"lightblue", }} to="/authentication/forgot-password">Forgot Passowrd?</Link></h4>

        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
