/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import Transaction from "examples/Icons/Transaction";
import ForgotPassword from "layouts/authentication/forgot-password";
import PasswordReset from "layouts/authentication/password-reset";
import ConfirmCode from "layouts/authentication/confirm-code";
import Customer from "layouts/customers";
import GroupIcon from "examples/Icons/customer";
import IconPeopleGroup from "examples/Icons/customer";
import { Logout } from "@mui/icons-material";
import LogoutUser from "layouts/authentication/logout";
import RouteProtection from "protectedRoutes/dashboardProtect";
import IconUsers from "examples/Icons/MerchantsIcon";
import Merchants from "layouts/merchants";
import MerchantPrice from "layouts/merchant price";
import IconHandshake from "examples/Icons/HandShake";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <RouteProtection component={Dashboard}/>,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Merchants",
    key: "merchants",
    route: "/merchants",
    icon: <IconUsers size="12px" />,
    component: <Merchants />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "/transactions",
    icon: <Document size="12px" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    route: "/customers",
    icon: <IconPeopleGroup size="12px" />,
    component: <Customer />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Merchant Charge",
    key: "charges",
    route: "/merchant-charges",
    icon: <IconHandshake size="12px" />,
    component: <MerchantPrice />,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <CreditCard size="12px" />,
    component: <Billing />,
    noCollapse: true,
  },

  
  
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: <Cube size="12px" />,
    component: <ForgotPassword />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Password Reset",
    key: "reset-password",
    route: "/authentication/reset-password",
    icon: <Cube size="12px" />,
    component: <PasswordReset/>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Confirm Code",
    key: "confirm-code",
    route: "/authentication/confirm-code",
    icon: <Cube size="12px" />,
    component: <ConfirmCode/>,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <Settings size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },

  /*{ type: "title", title: "Account Pages", key: "account-pages" },*/
  
  
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <Document size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <SpaceShip size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout-user",
    route: "/authentication/logout",
    icon: <SpaceShip size="12px" />,
    component: <LogoutUser />,
    noCollapse: true,
  },
];

export default routes;
