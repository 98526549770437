import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  maxWidth: '500px', // Optional: Set a maximum width for the dialog
};

export default function CreateTeamDialog({ isOpen, handleClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedValue, setSelectedValue] = useState(0);

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    console.log('Name:', name);
    console.log('Email:', email);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Invite New User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            fullWidth
            placeholder="John Doe"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            placeholder="john@doe.com"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <Select
            placeholder="Role"
            value={selectedValue}
            style={{ marginTop: "1rem", marginBottom: "2rem", width: '100%' }}
            onChange={handleValueChange}
            IconComponent={() => <ArrowDropDownIcon />}
          >
            <MenuItem value={0}>Select Role</MenuItem>
            <MenuItem value={1}>User</MenuItem>
            <MenuItem value={2}>Administrator</MenuItem>
          </Select>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "1.5rem" }}>
            <h4 style={{ fontSize: "15px", marginRight: "1rem" }}>User:</h4>
            <p style={{ fontSize: "12px" }}>This is best for staff without the need to update data.</p>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <h4 style={{ fontSize: "15px" }}>Administrator:</h4>
            <p style={{ fontSize: "12px" }}>This is best for the business owners and executives.</p>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            flex: 1,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            flex: 1,
            backgroundColor: 'lightblue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'blue',
            },
          }}
        >
          Create Account
        </Button>
      </DialogActions>
    </Dialog>
  );
}
