import axios from "axios";
import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import man from "assets/images/curved-images/reset.jpg";
import logo from "assets/images/fairsure_logo.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import SimpleBackdrop from "../backdrop"; // Import SimpleBackdrop
import SimpleSnackbar from "../snackbar"; // Import SimpleSnackbar



function PasswordReset() {
  const { token } = useParams(); // Extract the token parameter from the URL
  const navigate = useNavigate(); // Get the navigate function to navigate

  const [rememberMe, setRememberMe] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false); // State for loading
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // Password validation functions
  const isMinLengthValid = (password) => {
    return password.length >= 8;
  };

  const isLowerCaseValid = (password) => {
    return /[a-z]/.test(password);
  };

  const isUpperCaseValid = (password) => {
    return /[A-Z]/.test(password);
  };

  const isDigitValid = (password) => {
    return /\d/.test(password);
  };

  const isSpecialCharValid = (password) => {
    return /[!@#$%^&*]/.test(password);
  };

  const isPasswordValid =
    isMinLengthValid(newPassword) &&
    isLowerCaseValid(newPassword) &&
    isUpperCaseValid(newPassword) &&
    isDigitValid(newPassword) &&
    isSpecialCharValid(newPassword);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordBlur = () => {
    // Implement your logic to handle confirmPassword validation here
  };

  const passwordMatch = newPassword === confirmPassword;

  // Disable the "Confirm" button if password requirements are not met or passwords do not match
  const isButtonDisabled = !isPasswordValid || !passwordMatch;

  const handleResetPassword = async () => {
    try {
      setLoading(true); // Set loading to true

      // Make an API request to reset the password
      await axios.post("/api/reset-password", {
        password: newPassword,
        resetCode: token, // Pass the token as resetCode
      });

      // Handle success
      setSuccessMessage("Password reset successful.");

      // Wait for 5 seconds before navigating to the login page
      setTimeout(() => {
        navigate("authentication/sign-in"); // Navigate to the login page using navigate
      }, 5000);
    } catch (error) {
      // Handle API error
      setErrorMessage("Password reset failed. Please try again.");
      console.error("Password reset failed:", error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };



  return (
    <CoverLayout
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={logo}
            alt="Logo"
            width={40}
            height={40}
            style={{ marginRight: "8px" }}
          />
          <span>Reset Password</span>
        </div>
      }
      description="Enter your new password"
      image={man}
    >
      <SimpleBackdrop open={loading} /> {/* SimpleBackdrop for loading state */}
      <SimpleSnackbar
        open={!!successMessage}
        message={successMessage}
        variant="success"
      />
      <SimpleSnackbar
        open={!!errorMessage}
        message={errorMessage}
        variant="error"
      />

      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              New Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {/* Display password validation messages with icons */}
          <p style={{ color: "black", fontSize: 12 }}>
            Password must:
          </p>
          <div>
            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isMinLengthValid(newPassword) ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                )}
              </span>
              <SoftTypography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Be a minimum of 8 Characters with
              </SoftTypography>
            </SoftBox>

            {/* Add similar code blocks for other password requirements */}
            {/* Lowercase */}
            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isLowerCaseValid(newPassword) ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                )}
              </span>
              <SoftTypography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Lowercase letter (a-z)
              </SoftTypography>
            </SoftBox>

            {/* Uppercase */}
            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isUpperCaseValid(newPassword) ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                )}
              </span>
              <SoftTypography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Uppercase letter (A-Z)
              </SoftTypography>
            </SoftBox>

            {/* Number */}
            <SoftBox mb={0} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isDigitValid(newPassword) ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                )}
              </span>
              <SoftTypography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one number (0-9)
              </SoftTypography>
            </SoftBox>

            {/* Special Character */}
            <SoftBox mb={2} display="flex" alignItems="center">
              <span style={{ fontSize: "12px" }}>
                {isSpecialCharValid(newPassword) ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "12px",
                      marginRight: "3px",
                    }}
                  />
                )}
              </span>
              <SoftTypography variant="h6" color="grey" sx={{ fontSize: 11 }}>
                Include at least one Special Character
              </SoftTypography>
            </SoftBox>
          </div>
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Confirm New Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type={showPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={handleConfirmPasswordBlur}
          />
          {/* Display password match message */}
          <p style={{ color: "red", fontSize: 12 }}>
            {!passwordMatch && "Passwords do not match."}
          </p>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              fullWidth
              onClick = {handleResetPassword}
              disabled={isButtonDisabled} // Disable the button when requirements are not met or passwords don't match
            >
              Confirm
            </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default PasswordReset;
