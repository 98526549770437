import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from 'axios';
import AlertDialog from './popup';
import AlertDialog2 from './popup2';
import AlertDialog3 from './popup3';
import MessageDialog from './messgepopup';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


function SecurityInfoCard({ title, description, action }) {
  const [enable2FA, setEnable2FA] = useState(localStorage.getItem('isTwoFaEnabled') === 'true');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [oldPin, setOldPin] = useState('');
  const [newPinSet, setNewPinSet] = useState('');
  const [confirmNewPinSet, setConfirmNewPinSet] = useState('');
  const [newPinLoading, setNewPinLoading] = useState(false);
  const [changePinLoading, setChangePinLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [showOldPin, setShowOldPin] = useState(false);
  const [showNewPinSet, setShowNewPinSet] = useState(false);
  const [showConfirmNewPinSet, setShowConfirmNewPinSet] = useState(false);



  const [qrLink, setQrLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);




  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
  };

  


  const handleTogglePasswordVisibility = (setter, variable) => {
    setter(!variable);
  };




  const setUpTwoFa = async () => {
    // Get the token from local storage
    const token = localStorage.getItem('token');
  
    try {
      console.log('calling api....')
      // Make the GET request to the API endpoint for setting up 2FA
      const response = await axios.get('/api/user/setup-two-fa', {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      
      if (response.status === 200){ 
        if(response.data.data.setUpQrCode != ''){ 
          setQrLink(response.data.data.setupQrCode);
          
          setIsOpen(true);
        } else { 
          return false;
        }

        console.log('Updated qrLink:', qrLink);
      }
      
    } catch (error) {
      // Handle any errors that may occur during the request
      console.error('Error setting up 2FA:', error);
      throw error; // You can throw the error or handle it as needed
    }
  };



  




  const enable2FAauth = async (otp) => {
    const token = localStorage.getItem('token');
    const apiUrl = '/api/user/update-two-fa-status'; // Replace with your API endpoint
  
    const requestData = {
      enableTwoFA: true,
      otp: otp,
    };
  
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setIsLoading(true);
  
    try {
      const response = await axios.post(apiUrl, requestData, { headers });
  
      if (response.status === 200) {
        console.log('2FA enabled successfully:', response.data);
        // Handle the response as needed
        localStorage.setItem('isTwoFaEnabled', 'true');
        setIsLoading(false);
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error enabling 2FA:', error);
      throw error;
    }
  };







  const disable2FAauth = async (otp) => {
    const token = localStorage.getItem('token');
    const apiUrl = '/api/user/update-two-fa-status'; // Replace with your API endpoint
  
    const requestData = {
      enableTwoFA: false,
      otp: otp,
    };
  
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setIsLoading(true);
  
    try {
      const response = await axios.post(apiUrl, requestData, { headers });
  
      if (response.status === 200) {
        console.log('2FA disabled successfully:', response.data);
        // Handle the response as needed
        localStorage.setItem('isTwoFaEnabled', 'false');
        setIsLoading(false);
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error enabling 2FA:', error);
      throw error;
    }
  };
  
  





  




  const handleToggle2FA = () => {
    const isTwoFaSetup = localStorage.getItem('isTwoFaSetup');
    const isTwoFaEnabled = localStorage.getItem('isTwoFaEnabled');
  
    console.log('isTwoFaSetup:', isTwoFaSetup);
    console.log('isTwoFaEnabled:', isTwoFaEnabled);
  
    if (isTwoFaSetup === 'true') {
      if (isTwoFaEnabled === 'true') {
        setIsOpen(false);
        setIsOpen2(false);
        setIsOpen3(true);
        console.log('Disable dialog');
      } else {
        setIsOpen(false);
        setIsOpen3(false);
        setIsOpen2(true);
        console.log('Enable dialog');
      }
    } else {
      console.log('Setting up 2FA...');
      setUpTwoFa();
    }
  };
  
  
  
  


  const handleSetTransactionPin = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token not found in local storage.');
      return;
    }

    if (newPin !== confirmNewPin) {
      alert("New PIN and Confirm New PIN must match.");
      return;
    }

    try {
      setType("Set New Transaction Pin");
      setIsMessageDialogOpen(true);
      setSuccess(false);
      setError(false);
      setIsLoading(true)
      const response = await setTransactionPin(token, newPin, confirmNewPin);
      // Handle the response here
      console.log('Transaction PIN set successfully:', response.data);
      setMessage("Transaction PIN set successfully");
      setIsLoading(false)
      setError(false);
      setSuccess(true);
    } catch (error) {
      setMessage("Transaction PIN set failed");
      setIsLoading(false);
      setSuccess(false);
      setError(true);
      // Handle errors here
      console.error('Error setting transaction PIN:', error);
    }
  };

  const setTransactionPin = async (token, pin, pinConfirm) => {
    const apiUrl = '/api/merchant/set-pin'; // Replace with your API endpoint
    const requestData = {
      confirmPin: pinConfirm,
      pin: pin,
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    try {
      
      const response = await axios.post(apiUrl, requestData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };



  const handleChangeTransactionPin = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token not found in local storage.');
      return;
    }

    if (newPinSet !== confirmNewPinSet) {
      alert("New PIN and Confirm New PIN must match.");
      return;
    }

    try {
      setType("Change Transaction Pin");
      setIsMessageDialogOpen(true);
      setSuccess(false);
      setError(false);
      setIsLoading(true)
      const response = await changeTransactionPin(token, newPinSet, confirmNewPinSet, oldPin);
      // Handle the response here
      console.log('Transaction PIN changed successfully:', response.data);
      setMessage("Transaction PIN changed successfully");
      setIsLoading(false)
      setError(false);
      setSuccess(true);
    } catch (error) {
      setMessage("Transaction PIN change Failed");
      setIsLoading(false);
      setSuccess(false);
      setError(true);
      // Handle errors here
      console.error('Error changing transaction PIN:', error);
    }
  };

  const changeTransactionPin = async (token, pinSet, pinSetConfirm, pinOld) => {
    const apiUrl = '/api/merchant/change-pin'; // Replace with your API endpoint
    const requestData = {
      currentPin: pinOld,
      newPin: pinSet,
      confirmNewPin: pinSetConfirm
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    try {
      const response = await axios.post(apiUrl, requestData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleChangePassword = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token not found in local storage.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      alert("New Password and Confirm New Password must match.");
      return;
    }

    try {
      setType("Change Password");
      setIsMessageDialogOpen(true);
      setSuccess(false);
      setError(false);
      setIsLoading(true)
      const response = await changePassword(token, newPassword, confirmNewPassword, oldPassword);
      // Handle the response here
      console.log('Password changed successfully:', response.data);
      setMessage("Password changed successfully");
      setIsLoading(false)
      setError(false);
      setSuccess(true);
    } catch (error) {
      setMessage("Password change Failed");
      setIsLoading(false);
      setSuccess(false);
      setError(true);
      // Handle errors here
      console.error('Error changing password:', error);
    }
  };

  const changePassword = async (token, newPassword, confirmNewPassword, oldPassword) => {
    const apiUrl = '/api/user/change-password'; // Replace with your API endpoint
    const requestData = {
      confirmNewPassword: confirmNewPassword,
      currentPassword: oldPassword,
      newPassword: newPassword,
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    try {
      const response = await axios.post(apiUrl, requestData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };




  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
        </SoftBox>
        <Divider />
          <Grid container spacing={2}>
            {/* Enable/Disable 2FA */}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={enable2FA} onChange={handleToggle2FA} />}
                label="Enable/Disable 2FA"
              />
              <Divider variant="middle" />
            </Grid>
            {/* Change Password */}
            <Grid item xs={12}>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Change Password</h3>
                <Divider variant="middle" />
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="* Old Password"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowOldPassword, showOldPassword)}
                    
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="* New Password"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowNewPassword, showNewPassword)}
                    
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showConfirmNewPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="* Confirm New Password"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowConfirmNewPassword, showConfirmNewPassword)}
                    
                  >
                    {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Button disabled={oldPassword === '' || newPassword === '' || confirmNewPassword === ''} style={{color:"white"}} type="submit" variant="contained" color="primary" onClick={handleChangePassword}> 
                Save
              </Button>
              <Divider variant="middle" />
            </Grid>
            {/* Set Transaction PIN */}
            <Grid item xs={12}>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Set Transaction PIN</h3>
                <Divider variant="middle" />
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showNewPin ? "text" : "password"}
                    value={newPin}
                    onChange={(e) => setNewPin(e.target.value)}
                    placeholder="* New Pin"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowNewPin, showNewPin)}
                    
                  >
                    {showNewPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showConfirmNewPin ? "text" : "password"}
                    value={confirmNewPin}
                    onChange={(e) => setConfirmNewPin(e.target.value)}
                    placeholder="* Confirm New Pin"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowConfirmNewPin, showConfirmNewPin)}
                    
                  >
                    {showConfirmNewPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Button disabled={newPin === '' || confirmNewPin === ''} style={{color:"white"}} type="submit" variant="contained" color="primary" onClick={handleSetTransactionPin}> 
                Save
              </Button>
              <Divider variant="middle" />
            </Grid>
            {/* Change Transaction PIN */}
            <Grid item xs={12}>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>Change Transaction PIN</h3>
                <Divider variant="middle" />
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showOldPin ? "text" : "password"}
                    value={oldPin}
                    onChange={(e) => setOldPin(e.target.value)}
                    placeholder="* Old Pin"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowOldPin, showOldPin)}
                    
                  >
                    {showOldPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showNewPinSet ? "text" : "password"}
                    value={newPinSet}
                    onChange={(e) => setNewPinSet(e.target.value)}
                    placeholder="* New Pin"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowNewPinSet, showNewPinSet)}
                    
                  >
                    {showNewPinSet ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'center', border:'1px solid grey', borderRadius: "8px", width: "100%", padding: "2px 2px", marginBottom: "16px",}}>
                  <input
                    id="old-pin"
                    type={showConfirmNewPinSet ? "text" : "password"}
                    value={confirmNewPinSet}
                    onChange={(e) => setConfirmNewPinSet(e.target.value)}
                    placeholder="* Confirm New Pin"
                    style={{
                      width: "100%",
                      border: "none",
                      outline:'none',
                    }}
                  />
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility(setShowConfirmNewPinSet, showConfirmNewPinSet)}
                    
                  >
                    {showConfirmNewPinSet ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </Grid>
              <Button disabled={oldPin === '' || newPinSet === '' || confirmNewPinSet === ''} style={{color:"white"}} type="submit" variant="contained" color="primary" onClick={handleChangeTransactionPin}> 
                Save
              </Button>
            </Grid>
          </Grid>
      
      </CardContent> 
      {isOpen && ( 
        <AlertDialog isOpen={isOpen} link={qrLink} handleClose={() => setIsOpen(false)} enable2Fa={enable2FAauth} isLoading={isLoading}/>
      )}
      
      {isOpen2 && ( 
        <AlertDialog2 isOpen={isOpen2} enable2Fa={enable2FAauth} handleClose={() => setIsOpen2(false)} isLoading={isLoading}/>
      )}
      
      {isOpen3 && ( 
        <AlertDialog3 isOpen={isOpen3} disable2Fa={disable2FAauth} handleClose={() => setIsOpen3(false)} isLoading={isLoading}/>
      )}
      {isMessageDialogOpen && ( 
        <MessageDialog isOpen={isMessageDialogOpen} handleClose={() => setIsMessageDialogOpen(false)} isLoading={isLoading} success={success} error={error} type={type} message={message}/>
      )}

    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
SecurityInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default SecurityInfoCard;
