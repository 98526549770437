import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  width: '100%', // Make the dialog take the full width
};

const dataRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
};

const labelStyle = {
  fontWeight: 'bold',
  marginRight: '1rem',
};

const smallButtonStyle = {
  padding: '6px 12px', // Customize the padding for smaller buttons
  minWidth: 'auto', // Set the minimum width to auto
};

// Define lighter colors using RGBA
const lightRed = 'rgba(255, 99, 71, 0.7)'; // Light red
const lightBlue = 'rgba(0, 0, 255, 0.7)'; // Light blue
const lightGreen = 'rgba(0, 128, 0, 0.7)'; // Light green

export default function CreateTeamDialog({ isOpen, handleClose, rowData }) {
  const { billName, amount, amountType, dateCreated } = rowData || {};

  // Function to delete the bill (you can implement this logic)
  const handleDeleteBill = () => {
    // Implement delete logic here
    // You can use the billName or any other identifier
    handleClose(); // Close the dialog after deleting
  };

  // Function to share the bill (you can implement this logic)
  const handleShareBill = () => {
    // Implement share logic here
    // You can use the billName or any other identifier
  };

  // Function to download as CSV (you can implement this logic)
  const handleDownloadAsCSV = () => {
    // Implement CSV download logic here
    // You can use the billName or any other identifier
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Bill Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={dataRowStyle}>
            <span style={labelStyle}>Bill Name:</span>
            {billName || ''}
          </div>
          <div style={dataRowStyle}>
            <span style={labelStyle}>Amount:</span>
            {amount || ''}
          </div>
          <div style={dataRowStyle}>
            <span style={labelStyle}>Amount Type:</span>
            {amountType || ''}
          </div>
          <div style={dataRowStyle}>
            <span style={labelStyle}>Date Created:</span>
            {dateCreated || ''}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'grey',
            },
            fontSize: "9px", // Apply the custom style
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleDeleteBill}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: lightRed, // Use the lighter red color
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkred',
            },
            fontSize: "9px", // Apply the custom style
          }}
        >
          Delete Bill
        </Button>
        <Button
          onClick={handleShareBill}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: lightBlue, // Use the lighter blue color
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkblue',
            },
            fontSize: "9px", // Apply the custom style
          }}
        >
          Share Bill
        </Button>
        <Button
          onClick={handleDownloadAsCSV}
          className="small-button" // Apply the custom class for smaller buttons
          style={{
            flex: 1,
            backgroundColor: lightGreen, // Use the lighter green color
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkgreen',
            },
            fontSize: "9px", // Apply the custom style
          }}
        >
          Download as CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
}
