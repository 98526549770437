import React, { useState, useEffect } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import AlertDialog from "./popup";

export default function DynamicTable({ transactions }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="transaction table" stickyHeader>
        <TableHead style={{ width: "100%" }}>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Date/time</TableCell>
            <TableCell>Payment channel</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow
              key={transaction.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick(transaction)}
            >
              <TableCell component="th" scope="row">
                {transaction.merchantId}
              </TableCell>
              <TableCell>{`₦${transaction.amount}`}</TableCell>
              <TableCell>{transaction.paysureRef}</TableCell>
              <TableCell>{transaction.transactionDate}</TableCell>
              <TableCell>{transaction.channel}</TableCell>
              <TableCell>{transaction.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AlertDialog
        row={selectedRow}
        isOpen={isOpen}
        handleClose={handleClose}
        onClose={() => setSelectedRow(null)}
      />
    </TableContainer>
  );
}
