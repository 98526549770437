import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Add this import

function InstitutionForm(props) {
  const [proofOfInstitution, setProofOfInstitution] = useState('');
  const [signatoryCFO, setSignatoryCFO] = useState('');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="proof-of-institution">
          Proof of Institution
        </InputLabel>
        <Input
          id="proof-of-institution"
          type="file" // Change the input type to 'file'
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }} // Specify accepted file types
          onChange={(e) => setProofOfInstitution(e.target.files[0])} // Store the selected file in state
          startAdornment={ // Add the cloud upload icon
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="signatory-cfo">
          Signatory of the chief financial officer
        </InputLabel>
        <Input
          id="signatory-cfo"
          type="file" // Change the input type to 'file'
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }} // Specify accepted file types
          onChange={(e) => setSignatoryCFO(e.target.files[0])} // Store the selected file in state
          startAdornment={ // Add the cloud upload icon
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
    </Grid>
  );
}

export default InstitutionForm;
