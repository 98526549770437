/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMediaQuery, useTheme } from '@mui/material';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {Select} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";







import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useState, useEffect } from "react";


import React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import './action-menu.js'
import ApprovedMerchant from "./merchants-list.js";




function Merchants() {
  const [activeTab, setActiveTab] = useState(0);
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchID, setSearchID] = useState("");
  const [searchName, setSearchName] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [dateChecked, setDateChecked] = useState(false);


  

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleValueChange = (event) => {
    switch (event.target.value) {
      case 0:
        setSelectedValue(event.target.value);
        setSelectedStatus('');
        break;
      case 1:
        setSelectedValue(event.target.value);
        setSelectedStatus("true");
        break;
      case 2:
        setSelectedValue(event.target.value);
        setSelectedStatus("false");
        break;
      default:
        // Handle other cases if needed
    }
  };
  


  const handleFilterOptionClick = (filterOption) => {
    // Handle the selected filter option here
    console.log("Selected filter:", filterOption);

    // Close the menu
    handleCloseMenu();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const buttonSize = isXSmallScreen
    ? 'small'
    : isSmallScreen
    ? 'small'
    : isMediumScreen
    ? 'small'
    : 'xlarge'; // Adjust sizes as needed

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Merchants List</SoftTypography>
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', border:'1.5px solid grey', borderRadius:'5px'}}>
                <input
                  type="text"
                  value={searchName}
                  placeholder="Search Name"
                  onChange={(e) => setSearchName(e.target.value)}
                  style={{
                    padding: "5px",
                    fontSize: "12px",
                    width: "100px",  // Adjust the width as needed
                    borderRadius: '5px',
                    border:'none',
                    outline:'none'
                  }}
                />
                <span style={{ cursor: "pointer", color: "black", fontSize:'15px'}}>
                  <SearchIcon />
                </span>
              </div>
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', border:'1.5px solid grey', borderRadius:'5px'}}>
                <input
                  type="number"
                  value={searchID}
                  placeholder="Search ID"
                  onChange={(e) => setSearchID(e.target.value)}
                  style={{
                    padding: "5px",
                    fontSize: "12px",
                    width: "100px",  // Adjust the width as needed
                    borderRadius: '5px',
                    border:'none',
                    outline:'none',
                    '-moz-appearance': 'textfield', // Firefox
                    'appearance': 'textfield',      // Other browsers
                  }}
                />
                <span style={{ cursor: "pointer", color: "black", fontSize:'15px'}}>
                  <SearchIcon />
                </span>
              </div>
            </SoftBox>
            <div style={{width:"15rem", marginLeft:"1rem", marginBottom:"2rem"}}>
            <Button
                aria-controls="filter-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                variant="outlined"
                size={buttonSize}
                sx={{
                  height:30,
                  backgroundColor: 'primary',        // Set the background color
                  borderRadius: '8px',           // Set the border radius
                  color: 'darkblue',                // Set the text color
                  '&:hover': {
                    backgroundColor: 'white',  // Change color on hover
                  },
                }}
              >
                Filter Options
              </Button>
              
            </div>
            <SoftBox>
              
              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: "400px", // Set the maximum height
                    width: "300px",     // Set the width
                    
                  },
                }}
              >
                <MenuItem>
                  <div style={{marginBottom:"4rem", width:"100%"}}>
                    <div style={{ marginBottom: '5px' }}><Checkbox checked={checked} onChange={handleCheckboxChange} />Filter by Status</div>
                    <Select value={selectedValue} style={{ marginTop: 10, width:"100%"}} onChange={handleValueChange} disabled={!checked}>
                      <MenuItem value={0}>Select Status</MenuItem>
                      <MenuItem value={1}>Approved</MenuItem>
                      <MenuItem value={2}>Unapproved</MenuItem>
                      <MenuItem value={3}>Pending</MenuItem>
                      <MenuItem value={4}>Authorized</MenuItem>
                      <MenuItem value={5}>Unauthorized</MenuItem>
                      <MenuItem value={6}>Blocked</MenuItem>
                      <MenuItem value={7}>Whitelisted</MenuItem>
                    </Select>
  
                  </div>
                </MenuItem>

                
                {/* Add more filter options as needed */}
              </Menu>
            </SoftBox>
            
            <ApprovedMerchant searchName={searchName} searchID={searchID} searchStatus={selectedStatus}/>
        
            {/* ... Other code ... */}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Merchants;