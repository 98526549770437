import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Add this import
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";

function DynamicTrusteeFields({ trustees, onAddTrustee, onRemoveTrustee, onUpdateTrustee }) {
  return (
    <div>
      {trustees.map((trustee, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type='file'
            label={`Trustee ID ${index + 1}`}
            fullWidth
            variant="outlined"
            margin="normal"
            value={trustee}
            onChange={(e) => onUpdateTrustee(index, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CloudUploadIcon />
                </InputAdornment>
              ),
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '8px' }}>
            {index === 0 ? null : ( // Prevent removal of the first field
              <button
                type='button'
                onClick={() => onRemoveTrustee(index)}
                style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              >
                <DeleteIcon style={{ cursor: 'pointer' }} /> {/* "Delete" icon */}
              </button>
            )}
            <button
              type='button'
              onClick={() => onAddTrustee(index)}
              style={{ border: 'none', background: 'transparent', cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
              <AddIcon style={{ cursor: 'pointer' }} /> {/* "Add" icon */}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function NGOForm(props) {
  const [cacDocuments, setCacDocuments] = useState('');
  const [trusteesInfo, setTrusteesInfo] = useState([""]); // Initialize with one empty trustee field
  const [corporateBankAccount, setCorporateBankAccount] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [constitution, setConstitution] = useState('');
  const [scumlCertificate, setScumlCertificate] = useState('');
  const [bvn, setBvn] = useState('');

  const handleTrusteeChange = (index, value) => {
    const updatedTrustees = [...trusteesInfo];
    updatedTrustees[index] = value;
    setTrusteesInfo(updatedTrustees);
  };

  const handleAddTrustee = () => {
    const updatedTrustees = [...trusteesInfo, ""];
    setTrusteesInfo(updatedTrustees);
  };

  const handleRemoveTrustee = (index) => {
    if (index === 0) return; // Prevent removal of the first field
    const updatedTrustees = [...trusteesInfo];
    updatedTrustees.splice(index, 1);
    setTrusteesInfo(updatedTrustees);
  };

  return (
    <Grid container spacing={2}>
      {/* Full CAC Documents */}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="cac-documents">
          Full CAC Documents
        </InputLabel>
        <Input
          id="cac-documents"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }}
          onChange={(e) => setCacDocuments(e.target.files[0])}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/* Trustees’ Names, IDs */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="trustees-info">
          Trustees’ Names, IDs
        </InputLabel>
        <DynamicTrusteeFields
          trustees={trusteesInfo}
          onAddTrustee={handleAddTrustee}
          onRemoveTrustee={handleRemoveTrustee}
          onUpdateTrustee={handleTrusteeChange}
        />
      </Grid>
      {/* BVN */}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="bvn">
          BVN
        </InputLabel>
        <Input
          id="bvn"
          type="text"
          fullWidth
          placeholder='* BVN'
          disableUnderline
          variant="outlined"
          margin="normal"
          value={bvn}
          onChange={(e) => setBvn(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/* Corporate Bank Account */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="ngos-corporate-account">
          Corporate Bank Account details
        </InputLabel>
        <Input
          id="ngos-corporate-account"
          type="text"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          value={corporateBankAccount}
          onChange={(e) => setCorporateBankAccount(e.target.value)}
        />
      </Grid>
      {/* Operational Business Address */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="ngos-business-address">
          Operational Business Address
        </InputLabel>
        <Input
          id="ngos-business-address"
          type="text"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          value={businessAddress}
          onChange={(e) => setBusinessAddress(e.target.value)}
        />
      </Grid>
      {/* Constitution/Memorandum of Guidance */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="constitution">
          Constitution/Memorandum of Guidance
        </InputLabel>
        <Input
          id="constitution"
          type="text"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          value={constitution}
          onChange={(e) => setConstitution(e.target.value)}
        />
      </Grid>
      {/* SCUML Certificate */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="scuml-certificate">
          SCUML Certificate
        </InputLabel>
        <Input
          id="scuml-certificate"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }}
          onChange={(e) => setScumlCertificate(e.target.files[0])}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
    </Grid>
  );
}

export default NGOForm;
