import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField"; // Import Material-UI TextField
import Button from "@mui/material/Button"; // Import Material-UI Button
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid"; // Import Material-UI Grid
import { styled } from '@mui/material/styles'; // Import styled from Material-UI
import InputLabel from "@mui/material/InputLabel"; // Import Material-UI InputLabel

// Define custom styles using the `styled` function
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%', // Default width for xs screen size
  [theme.breakpoints.up('sm')]: {
    width: '100%', // 60% width for sm screen size and larger
  },
  [theme.breakpoints.up('md')]: {
    width: '65%', // 40% width for md screen size and larger
  },
  [theme.breakpoints.up('lg')]: {
    width: '45%', // 30% width for lg screen size and larger
  },
}));

function BusinessInfoCard({ title, description, action }) {
  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [State, setState] = useState('');
  const [City, setCity] = useState('');

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // You can access individual form field values using the state variables
    // Add logic for submitting the data to your server or performing other actions
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        <SoftBox>
          {/* Form fields */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="business-name">Business Name</InputLabel>
                <CustomTextField
                  id="business-name"
                  placeholder='*Business Name'
                  variant="outlined"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="business-email">Business Email</InputLabel>
                <CustomTextField
                  id="business-email"
                  placeholder='*Business Email'
                  variant="outlined"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="business-phone">Business Phone</InputLabel>
                <CustomTextField
                  id="business-phone"
                  placeholder='*Business Phone'
                  variant="outlined"
                  value={businessPhone}
                  onChange={(e) => setBusinessPhone(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="business-address">Business Address</InputLabel>
                <CustomTextField
                  id="business-address"
                  placeholder='*Business Address'
                  variant="outlined"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>
              {/*
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="state">State</InputLabel>
                <CustomTextField
                  id="state"
                  placeholder='*State'
                  variant="outlined"
                  value={State}
                  onChange={(e) => setState(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel style={{fontSize:"15px"}} htmlFor="city">City</InputLabel>
                <CustomTextField
                  id="city"
                  placeholder='*City'
                  variant="outlined"
                  value={City}
                  onChange={(e) => setCity(e.target.value)}
                  margin="normal"
                  disabled
                />
              </Grid>*/}
            </Grid>
            
          </form>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the BusinessInfoCard
BusinessInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default BusinessInfoCard;
