import React, { useState, useEffect} from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import SoftBox from "components/SoftBox";
import FullScreenDialog from "./merchant-detail";
import TransactionScreenDialog from "./merchant-transactions";
import Axios from "axios"; // Import Axios library
import SimpleBackdrop from "../tables/backdrop";
import Alert from "@mui/material/Alert";



export default function ApprovedMerchant({searchName, searchID, searchStatus}) {
  const [rows, setRows] = useState([]);
  const [merchantData, setMerchantData] = useState([])
  const [selectedRow, setSelectedRow] = useState(null);
  const [isRowDialogOpen, setRowDialogOpen] = useState(false);
  const [isButtonDialogOpen, setButtonDialogOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [error, setIsError] = useState(false);
  //const [searchText, setSearchText] = useState('12');





  const fetchMerchantsFromAPI = async () => {
    try {
      setIsError(false);
      setIsLoading(true);

      // Fetch the token from local storage
      const storedToken = localStorage.getItem("token");


      // Define the API URL with query parameters
      const apiUrl = '/api/admin/merchant/merchant/all?page_no=1&page_size=100000';

      // Fetch transactions from the API using Axios
      const response = await Axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      const data = response.data.data.content;
      return data;
    } catch (error) {
      setIsLoading(false);
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);

      console.error("Error fetching transactions:", error);
      return [];
    }
  };

  useEffect(() => {
    // Fetch transactions when the component mounts
    fetchMerchantsFromAPI().then((data) => {
      setRows(data);
      setIsLoading(false);
    });

    
  }, []);

  useEffect(() => { 
    // Check if rows is not null before filtering
    if (rows !== null) {
      let datas = rows;  // Initialize datas with rows
    
      if (searchID.trim() !== '') { 
        datas = datas.filter((data) => 
          data.userId.toString().includes(searchID)
        );
      }

      if(searchName.trim() !== ''){
         datas = datas.filter((data) =>  
         data.name.includes(searchName))
      }

      if(searchStatus.trim() !== ''){ 
        datas = datas.filter((data) =>  
        data.approved.toString() === searchStatus)
      }
    
      setMerchantData(datas);
    }
  }, [searchName, searchID, searchStatus, rows]);
  
  
  
  


 



  const handleRowClick = (row) => {
    setSelectedRow(row);
    setRowDialogOpen(true);
  };

  const handleButtonClick = (row) => {
    setSelectedRow(row);
    setButtonDialogOpen(true);
  };

  const handleCloseRowDialog = () => {
    setRowDialogOpen(false);
  };

  const handleCloseButtonDialog = () => {
    setButtonDialogOpen(false);
  };

  

  return (
    <SoftBox>
      {error && (
        <Alert variant="outlined" severity="error">
          Failed to fetch data, check network connection!
        </Alert>
      )}
      {loading && ( 
        <SimpleBackdrop open={loading}/>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead style={{ width: "100%" }}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Merchant ID</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {merchantData.map((row, index) => (
              <TableRow key={index} onClick={() => handleRowClick(row)} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.approved.toString()}</TableCell>
                <TableCell
                  style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click from triggering
                    handleButtonClick(row);
                  }}
                >
                  Details
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isButtonDialogOpen && (
        <FullScreenDialog
          isOpen={isButtonDialogOpen}
          handleClose={handleCloseButtonDialog}
          rowData={selectedRow}
        />
      )}
      {isRowDialogOpen && (
        <TransactionScreenDialog
          isOpen={isRowDialogOpen}
          handleClose={handleCloseRowDialog}
          rowData={selectedRow}
        />
      )}
      

    </SoftBox>
  );
}
