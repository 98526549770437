import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import { useEffect, useState } from "react";
  
function createData(name, username, email, phone, website) {
 return { name, username, email, phone, website };
}
  
const rows = [];
  
export default function TeamTable() {
 const [data, setData] = useState([]);
 const [selectedRow, setSelectedRow] = useState(null);
 const [isOpen, setisOpen] = useState(false);

  
 useEffect(() => {
   axios
     .get("https://jsonplaceholder.typicode.com/users")
     .then((res) => {
       setData(res.data);
       console.log("Result:", data);
     })
     .catch((error) => {
       console.log(error);
     });
 }, []);


  

 const handleRowClick = (row) => {
    console.log('Row clicked:', row);
    setSelectedRow(row);
    setisOpen(true);

  };


 const handleOpen = () => {
  setisOpen(true);
 };

 const handleClose = () => {
  setisOpen(false);
 };

  

  
 return (
  <body>
   <TableContainer component={Paper}>
     <Table aria-label="simple table" stickyHeader>
       <TableHead style={{width:"100%"}}>
         <TableRow>
           <TableCell>Name of User</TableCell>
           <TableCell align="left">Email</TableCell>
           <TableCell align="left">Role</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
         {data.map((row) => (
           <TableRow key={row.id} style={{cursor:"pointer"}} onClick={()=> handleRowClick(row)}>
             <TableCell component="th" scope="row">
               {row.name}
             </TableCell>
             <TableCell align="left">{row.email}</TableCell>
             <TableCell align="left">Administrator</TableCell>
           </TableRow>
         ))}
       </TableBody>
     </Table>
   </TableContainer>
   
      
  </body>
 );
}