import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CsvDownloader from 'react-csv-downloader';

// Define lighter colors using RGBA
const lightRed = 'rgba(255, 99, 71, 0.7)'; // Light red
const lightBlue = 'rgba(0, 0, 255, 0.7)'; // Light blue
const lightGreen = 'rgba(0, 128, 0, 0.7)'; // Light green

export default function AlertDialog({ row, isOpen, handleClose }) {

  if (!row) {
    return null; // or handle it in a way that makes sense for your use case
  }
  
  const rowEntries = Object.entries(row);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '10px',
            padding: '1rem', // Adjust the padding as needed
            width: '100%', // Make the dialog take the full width
          },
        }}
      >
        <DialogTitle>{"Transaction Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {rowEntries.map(([key, value]) => (
              <div
                key={key}
                style={{
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '13px', // Adjust the font size as needed
                }}
              >
                <span>{key}:</span>
                {value}
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CsvDownloader
            datas={[row]}
            filename={`transaction_${row?.paysureRef}`}
            extension=".csv"
          >
            <Button
              style={{
                flex: 1,
                backgroundColor: lightBlue, // Use the lighter blue color
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkblue',
                },
                fontSize: '12px', // Adjust the font size as needed
              }}
            >
              Export to CSV
            </Button>
          </CsvDownloader>
          <Button
            onClick={handleClose}
            style={{
              flex: 1,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'grey',
              },
              fontSize: '12px', // Adjust the font size as needed
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
