import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";

function DynamicProprietorFields({ proprietors, onAddProprietor, onRemoveProprietor, onUpdateProprietor }) {
  return (
    <div>
      {proprietors.map((proprietor, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type='file'
            label={`Proprietor ID ${index + 1}`}
            fullWidth
            variant="outlined"
            margin="normal"
            value={proprietor}
            onChange={(e) => onUpdateProprietor(index, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CloudUploadIcon />
                </InputAdornment>
              ),
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '8px' }}>
            {index === 0 ? null : ( // Prevent removal of the first field
              <button
                type='button'
                onClick={() => onRemoveProprietor(index)}
                style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              >
                <DeleteIcon style={{ cursor: 'pointer', }} /> {/* "Delete" icon */}
              </button>
            )}
            <button
              type='button'
              onClick={() => onAddProprietor(index)}
              style={{ border: 'none', background: 'transparent', cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
              <AddIcon style={{ cursor: 'pointer', }} /> {/* "Add" icon */}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function BusinessForm(props) {
  const [cacCertificateFile, setCacCertificateFile] = useState(null);
  const [corporateBankAccountFile, setCorporateBankAccountFile] = useState(null);
  const [businessAddressFile, setBusinessAddressFile] = useState(null);
  const [proprietors, setProprietors] = useState([""]); // Initialize with one empty proprietor field

  const handleFileChange = (event, setter) => {
    const file = event.target.files[0];
    setter(file);
  };

  const handleProprietorChange = (index, value) => {
    const updatedProprietors = [...proprietors];
    updatedProprietors[index] = value;
    setProprietors(updatedProprietors);
  };

  const handleAddProprietor = () => {
    const updatedProprietors = [...proprietors, ""];
    setProprietors(updatedProprietors);
  };

  const handleRemoveProprietor = (index) => {
    if (index === 0) return; // Prevent removal of the first field
    const updatedProprietors = [...proprietors];
    updatedProprietors.splice(index, 1);
    setProprietors(updatedProprietors);
  };

  return (
    <Grid container spacing={2}>
      {/* CAC Certificate */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="cac-certificate">
          CAC Certificate & Application for Registration for Business Name form (CAC/BN/1 or Form A)
        </InputLabel>
        <Input
          id="cac-certificate"
          type="file"
          fullWidth
          variant="outlined"
          disableUnderline
          margin="normal"
          inputProps={{
            accept: "image/*",
          }}
          onChange={(event) => handleFileChange(event, setCacCertificateFile)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/* Proprietors */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }}>
          Proprietor's Name, ID
        </InputLabel>
        <DynamicProprietorFields
          proprietors={proprietors}
          onAddProprietor={handleAddProprietor}
          onRemoveProprietor={handleRemoveProprietor}
          onUpdateProprietor={handleProprietorChange}
        />
      </Grid>
      {/* Corporate Bank Account */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="corporate-account">
          Corporate Bank Account details
        </InputLabel>
        <Input
          id="corporate-account"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{
            accept: "image/*",
          }}
          onChange={(event) => handleFileChange(event, setCorporateBankAccountFile)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/* Business Address */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="business-address">
          Operational Business Address
        </InputLabel>
        <Input
          id="business-address"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{
            accept: "image/*",
          }}
          onChange={(event) => handleFileChange(event, setBusinessAddressFile)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
    </Grid>
  );
}

export default BusinessForm;
