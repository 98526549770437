import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutUser = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear all stored data in local storage
    localStorage.removeItem('userId');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
    localStorage.removeItem('balance');
    localStorage.removeItem('role');
    localStorage.removeItem('lastLoginDate');
    localStorage.removeItem('isActive');
    localStorage.removeItem('token');
    localStorage.clear();

    console.log("Cleared Data Successfully")

    // Redirect to the login page
    navigate('/authentication/sign-in');
  };

  // Call handleLogout when this component renders
  React.useEffect(() => {
    handleLogout();
  }, []);

  return null; // This component doesn't render anything
};

export default LogoutUser;
