import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box'; // Import Box for button placement
import { useMediaQuery, useTheme } from '@mui/material';

// Define your table data
const rows = [
  { name: 'Name of team member', email: 'Email', userRole: 'User Role (admin and user)' },
  // Add more rows as needed
];

function TeamInfoCard({ title, description, action }) {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(""); // State for the TextField
  const [showTextField, setShowTextField] = useState(false); // State to conditionally show TextField

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMessageChange = (event) => {
    // Update the message state when the user types in the TextField
    setMessage(event.target.value);
  };

  const handleRejectMerchantClick = () => {
    // When "Reject Merchant" button is clicked, show the TextField
    setShowTextField(true);
  };

  return (
    <Card sx={{ height: "100%" }}>
      {/* Buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        {/* Updated to "flex-end" */}
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            variant="contained"
            style={{
              marginRight: "0.5rem",
              fontSize: "8px",
              color: 'white', // Text color
              width: isXSmallScreen ? '10%' : isSmallScreen ? '10%' : isMediumScreen ? '10%' : isLargeScreen ? '10%' : '10%',
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            style={{
              fontSize: "8px",
              color: 'white', // Text color
              width: isXSmallScreen ? '10%' : isSmallScreen ? '10%' : isMediumScreen ? '10%' : isLargeScreen ? '10%' : '10%',
            }}
            onClick={handleRejectMerchantClick}
          >
            Reject
          </Button>
        </Box>
      </Box>
      {/* Title */}
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      {/* Description */}
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        {/* Conditional rendering of the TextField with adjusted width */}
        {showTextField && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder='Send Merchant a Message'
              fullWidth
              value={message}
              onChange={(e) => handleMessageChange(e)}
              sx={{
                width: isXSmallScreen ? '100%' : isSmallScreen ? '80%' : isMediumScreen ? '70%' : isLargeScreen ? '60%' : '50%',
              }}
            />
            <IconButton style={{ color: "lightblue" }}>
              <SendIcon />
            </IconButton>
          </div>
        )}
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the TeamInfoCard
TeamInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default TeamInfoCard;
