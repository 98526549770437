import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';


export default function SimpleSnackbar({open, handleClose, messages}) {

  
    
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
        open={open}
        onClose={handleClose}
        message= {messages}
        sx={{
            backgroundColor: "white", // Customize background color based on the variant
          }}
        
      />
    </Box>
  );
}
