import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function Transaction({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>transaction</title>
      <g id="Basic-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Rounded-Icons"
          transform="translate(-1869.000000, -293.000000)"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        >
          <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
            <g id="transaction" transform="translate(153.000000, 2.000000)">
              {/* Customize the path elements here */}
              <rect x="10" y="15" width="22" height="12" rx="2" />
              <path d="M30,11 L26,15 L23,12 L20,16 L17,13 L13,17" id="Path" opacity="0.6" />

              {/* Arrow Up SVG */}
              <svg
                x="12"
                y="6"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 19V5M5 12l7-7 7 7" />
              </svg>

              {/* Arrow Down SVG */}
              <svg
                x="22"
                y="12"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 5v14M5 12l7 7 7-7" />
              </svg>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Transaction.defaultProps = {
  color: "dark",
  size: "16px",
};

Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Transaction;
